.g-gift-cards {
  display: flex;
  padding-bottom: 6rem;

  & > div:first-child {
    width: 50%;
    margin-right: 1em;
  }

  & > div:last-child {
    width: 50%;
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    & > div:first-child {
      width: 100%;
      margin-right: 0em;
      margin-bottom: 1em;
    }

    & > div:last-child {
      width: 100%;
    }
  }

  .inline-dropdown {
    background-color: #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
    padding: 2px 5px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 1rem !important;
    font-family: 'Inconsolata', monospace !important;
    &:hover {
      background-color: #fbfbfb;
      border-bottom: 1px dashed #ddd;
    }
    .menu .item {
      padding: 8px 10px !important;
      .text {
        font-size: 0.9em !important;
      }
    }
  }

  .purchased {
    &:not(:first-child) {
      border-top: 1px solid #eee;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 1em 0.5em;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1em;
    font-weight: 400;

    .info {
      .email {
        font-family: 'Inconsolata';
      }
    }

    .status {
      // border: 1px solid #ccc;
      padding: 3px 6px 2px 6px;
      margin-left: 0.5rem;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 600;

      &.unused {
        background-color: #21ba45;
        color: #fafafa;
      }

      &.emailed,
      &.shared {
        background-color: #21ba45;
        color: #fafafa;
      }

      &.redeemed {
        background-color: #eee;
        color: #888;
      }
    }
  }

  .gift-card-container {
    display: flex;
    flex-direction: column;

    .gift-card {
      width: 100%;
      margin: 0 0 1rem 0;
      border-radius: 8px;
      border: 1px solid #eee;
      padding: 1.5rem;
      .price {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 700;
        color: #333;
        margin-bottom: 0rem !important;
        margin-right: 0.5rem;
        text-align: right;
      }
      .strike-out {
        font-size: 1.5rem;
        color: #ccc;
        text-decoration: line-through;
      }
      .item {
        text-align: left;
        font-size: 1.3rem;
        font-weight: 700;
        margin-bottom: 4px !important;
        color: #333;
      }
      .description {
        text-align: left;
        color: #fff;
        font-weight: 700;
        font-size: 0.9rem;
        background-color: #55b021;
        border-radius: 4px;
        padding: 5px 10px 3px 10px;
      }
    }
  }
}
