// Used in query tool tooltip
.circle-color {
  width: 6px;
  height: 6px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 3px;
  margin-bottom: 1px;
  padding-right: 2px;
}

// Used in query tool data form
.line-color {
  width: 4px;
  height: 34px;
  display: inline-block;
  border-radius: 3px;
}

.circle-label::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 3px;
  margin-bottom: 1px;
}

.circle-label,
.text-label {
  padding-left: 2px;
}

.label-text {
  font-family: 'Inconsolata', monospace;
  text-align: center;
  padding: 1px 3px !important;
  margin-right: 1px;
  display: inline-block;
  margin-bottom: 1px;
  border-radius: 2px;
  font-size: 0.8rem !important;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  overflow-wrap: break-word;
}

.label-employee.compensation::before,
.label-bg-employee {
  background-color: #b0a8b9;
}

.label-employee.compensation {
  color: #b0a8b9 !important;
}

.label-depository::before,
.label-cash::before,
.label-bg-depository,
.label-bg-cash {
  background-color: #16b9b1;
}
.label-depository,
.label-cash {
  color: #16b9b1 !important;
}

.label-cryptocurrency::before,
.label-bg-cryptocurrency {
  background-color: #9223be;
}

.label-cryptocurrency {
  color: #9223be !important;
}

.label-credit::before,
.label-bg-credit {
  background-color: #158ae4;
}
.label-credit {
  color: #158ae4 !important;
}

.label-real.estate::before,
.label-bg-real.estate {
  background-color: #cc11a4;
}

.label-real.estate {
  color: #cc11a4 !important;
}

.label-brokerage::before,
.label-bg-brokerage,
.label-investment::before,
.label-bg-investment {
  background-color: #91c549;
}
.label-investment,
.label-brokerage {
  color: #91c549 !important;
}

.label-vehicle::before,
.label-bg-vehicle {
  background-color: #cc11a4;
}

.label-vehicle {
  color: #cc11a4 !important;
}

.label-loan::before,
.label-bg-loan {
  background-color: #c93d3d;
}
.label-loan {
  color: #c93d3d !important;
}

.label-yearly {
  color: #a82e63 !important;
}

.label-twice-a-year {
  color: #a82e63 !important;
}

.label-every-4-months {
  color: #a82e63 !important;
}

.label-every-3-months {
  color: #7472c3 !important;
}

.label-every-2-months {
  color: #7472c3 !important;
}

.label-monthly {
  color: #7472c3 !important;
}

.label-twice-a-month {
  color: #00a9ba !important;
}

.label-every-2-weeks {
  color: #00a9ba !important;
}

.label-once-a-week {
  color: #00a9ba !important;
}

.label-custom {
  color: #129278 !important;
}

// Other labels
.label-other::before {
  background-color: #777;
}
.label-other {
  color: #777 !important;
}

.form-label {
  color: #aeaeae;
  margin: 0.4em 0 0.3em 0;
  font-size: 0.9285rem;
  font-weight: 600;
  text-transform: uppercase;
}

.or {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ddd;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;

  &:before {
    border-top: 1px solid #dfdfdf;
    content: '';
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: -1;
  }

  span {
    /* to hide the lines from behind the text, you have to set the background color the same as the container */
    background: #fff;
    padding: 0 15px;
    margin-top: 2px;
  }
}

.transaction-circle-tag {
  padding: 4px !important;
  margin-right: 5px;
  display: inline-block;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  // border-color: #ccc;
}

.transaction-tag {
  font-family: 'Inconsolata', monospace;
  text-align: center;
  padding: 1px 3px !important;
  border: 1px solid #eee;
  margin-right: 1px;
  display: inline-block;
  margin-bottom: 1px;
  border-radius: 2px;
  font-size: 0.8rem !important;
  background-color: #eee;
  font-weight: 300;
  color: #333;
  text-transform: uppercase;
  width: 100%;
  overflow-wrap: break-word;
  line-height: 1rem;

  &:not(.no-hover):hover {
    opacity: 0.6;
    // border-color: #333 !important;
  }

  &.in-popup {
    display: inline !important;
    white-space: nowrap;
  }

  &.short {
    margin-left: 2px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 25px;
    color: #888;
    background-color: #eee;
    white-space: nowrap;
  }
}

.coming-soon-tag {
  font-family: 'Inconsolata', monospace;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  padding: 0px 5px 0px 5px !important;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem !important;
  background-color: $brand-yellow;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.new-tag {
  font-family: 'Inconsolata', monospace;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  padding: 0px 5px 0px 5px !important;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem !important;
  background-color: $brand-green;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.color-block {
  display: inline-block;
  padding: 2px 6px;
  margin: 2px;
  border-radius: 2px;
  color: #222;
}

i.icon.light-grey {
  color: #aeaeae !important;
  font-size: 1em;
}

// Custom budgets
.custom-budget-label {
  white-space: nowrap;
  margin: 0 !important;
  vertical-align: middle !important;
  padding: 4px 9px 3px 9px !important;
}
