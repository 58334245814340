#g-developers {
  display: flex;

  & > div:first-child {
    width: 50%;
    margin-right: 1em;
  }

  & > div:last-child {
    width: 50%;
  }

  @media (max-width: $responsive-width) {
    flex-direction: column;

    & > div:first-child {
      width: 100%;
      margin-right: 0em;
      margin-bottom: 1em;
    }

    & > div:last-child {
      width: 100%;
    }
  }

  .token {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1em;
    font-weight: 400;
    border-bottom: 1px solid #eee;

    p.info {
      margin-bottom: 0;
    }

    &:first-child {
      padding-top: 0.25em;
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0.25em;
    }

    .status {
      dispay: inline-block;
      margin-right: 6px;
      padding: 2px 5px;
      border-radius: 3px;
      text-transform: uppercase;
      font-size: 0.85rem;
      font-family: 'Inconsolata';
      font-weight: 300;

      &.label {
        background-color: #44958c;
        color: #fff;
      }
    }
  }
}

div.api-token {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-family: 'Inconsolata';
  padding: 0.5em 0.75em;
  border: 1px solid #888;
  background-color: #fafafa;
  border-radius: 4px;
  margin-bottom: 1rem;

  .copy-link {
    margin-left: 10px;
  }
}
