.g-referral {
  display: flex;

  & > div:first-child {
    width: 50%;
    margin-right: 1em;
  }

  & > div:last-child {
    width: 50%;
  }

  @media (max-width: $responsive-width) {
    flex-direction: column;

    & > div:first-child {
      width: 100%;
      margin-right: 0em;
      margin-bottom: 1em;
    }

    & > div:last-child {
      width: 100%;
    }
  }

  p.referral-link {
    margin-bottom: 0;
    background-color: #fff;
    padding: 5px 10px;
    border: 1px solid #dedede;
    border-radius: 3px;
    font-family: 'Inconsolata';
    display: inline-block;
    font-size: 1.2rem;
  }

  p.copy-link {
    color: #aeaeae;
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 0.9285rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .referred {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.35em 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1em;
    font-weight: 400;

    .info {
      .email {
        font-family: 'Inconsolata';
      }
    }

    .status {
      // border: 1px solid #ccc;
      padding: 5px 6px 2px 6px;
      margin-left: 0.5rem;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 600;

      &.active {
        background-color: #21ba45;
        color: #fafafa;
      }

      &.trialing {
        background-color: #eee;
        color: #888;
      }
    }
  }
}
