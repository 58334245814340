.task-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  max-width: 260px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 260px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 1px #eee;
  padding: 0.75em;

  &.task-done {
    opacity: 0.5;
    // border-color: #21ba45;

    & .task-checkmark {
      background-image: url('../images/checkmark-white.png');
      border-color: #21ba45;
      background-color: #21ba45;
    }

    &:hover {
      opacity: 1;
    }

    .task-help {
      display: none;
    }
  }

  &:not(.task-done) {
    // border-color: #e05951
  }

  &:hover:not(.task-done):not(.no-hover) {
    border-color: #8d8d8d;
    cursor: pointer;

    .task-checkmark {
      background-image: url('../images/checkmark-white.png');
      border-color: #ddd;
      background-color: #ddd;
    }

    .task-help {
      display: none;
    }
  }

  .task-card-icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.recurring-task-icon {
      width: 41px;
      height: 41px;
      background-image: url('../images/review-recurring.svg');
    }

    &.transactions-task-icon {
      width: 39px;
      height: 39px;
      background-image: url('../images/review-transactions.svg');
    }

    &.budget-task-icon {
      width: 40px;
      height: 40px;
      background-image: url('../images/review-budget.svg');
    }

    &.accounts-task-icon {
      width: 38px;
      height: 38px;
      background-image: url('../images/bank.png');
    }
  }

  .task-checkmark {
    background: url('../images/checkmark.png') center no-repeat;
    background-size: 8px 8px;
    position: absolute;
    top: 0.6em;
    right: 0.6em;
    border: 1px solid #ddd;
    border-radius: 12px;
    width: 18px;
    height: 18px;
  }

  .task-help {
    background: url('../images/question.svg') center no-repeat;
    background-size: 10px 11px;
    position: absolute;
    top: 0.6em;
    left: 0.6em;
    border: 1px solid #ddd;
    border-radius: 12px;
    width: 18px;
    height: 18px;
  }

  .task-text {
    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
      margin-top: 3px;
      display: block;
      color: #666666;
      font-size: 0.9em;
      font-weight: 600;
      text-transform: uppercase;
    }

    .subtitle {
      text-align: center;
      display: block;
      font-weight: 400;
      color: #888;
      font-size: 0.85em;
      line-height: 1.2em;
    }
  }
}
