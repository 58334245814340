.ui.toggle.checkbox {
  input:focus:not(:checked) ~ label {
    color: #aaa;
  }
  &.checked label, &.checked ~ label {
    color: #444;
  }
  label {
    font-size: 0.9em;
    color: #aaa;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
}
