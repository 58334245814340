.StripeElement {
  border: 1px solid #dcdcdc;
  padding: 8px;
  font-size: 1.1em;
  margin: 5px 0px 10px 0px;
  border-radius: 4px;

  &:hover {
    border-color: #a5a5a5;
  }
}
