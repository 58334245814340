#g-register {
  .register-container {
    display: flex;

    & > div {
      width: 50%;
      padding: 0 1.25rem;
    }

    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 4px 3px #eee;
    width: 95%;
    max-width: 800px;
    margin: 4em auto 4em auto;
    padding: 3em 2em;

    @media (max-width: $responsive-width) {
      display: block;

      & > div {
        width: 100%;
        padding: 0 1rem;
      }

      width: 70%;
      padding: 2em;
    }

    @media (max-width: 700px) {
      width: 80%;
    }

    @media (max-width: 500px) {
      width: 85%;
    }
    @media (max-width: 400px) {
      width: 90%;
      margin: 2em auto;
      padding: 2em 1.5em;
    }

    .register-content {
      display: flex;
    }

    .message {
      text-align: center;
      margin: 0;
    }

    .error {
      text-align: center;
      color: #e05951;
      margin: 0;
      &.login-error {
        margin-top: 8px;
      }
    }

    .logged-out-form {
      width: 100%;
      margin: 0em;
      display: flex;
      flex-direction: column;

      button {
        margin-top: 1em;
      }
    }

    .forgot {
      width: 100%;
      text-align: right;
    }

    p.register {
      text-align: center;
    }
  }
}

.g-register-modal {
  .setup-option {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 1rem;
    color: #919191;

    .icon {
      color: #e5e5e5;
    }

    p {
      text-align: center;
    }

    &.added {
      border: 1px solid #44958c;
      color: #44958c;

      .icon {
        color: #44958c;
      }
    }

    &:hover {
      cursor: pointer;
    }
    &:hover:not(.added) {
      border: 1px solid #919191;
      color: #919191;
      .icon {
        color: #919191;
      }
    }

    img {
      width: 90px !important;
      height: 90px !important;
    }
  }

  .setup-primary-option {
    align-items: center;
    flex-direction: column;
    width: 50%;
    margin-bottom: 0.7em;

    p {
      margin-top: 1rem;
    }
  }

  .setup-secondary-option {
    align-items: flex-start;
    width: 100%;

    p {
      margin: 3px 0 0 0 !important;
    }

    .icon {
      margin-top: 2px;
    }
  }
}
