.g-recurring {
  .p-recurring-table {
    // Normal
    .table-cell-merchant {
      width: 170px;
    }
    .table-cell-category {
      width: 120px;
    }
    .table-cell-cadence {
      width: 110px;
    }
    .table-cell-billing-day {
      width: 110px;
    }
    .table-cell-amount {
      width: 110px;
    }
    .table-cell-status {
      width: 50px;
    }
    .table-cell-arrow {
      width: 40px;
    }
    // Suggested
    .table-cell-merchant-suggested {
      width: 250px;
    }
    .table-cell-account-suggested {
      width: 200px;
    }
    .table-cell-tx-suggested {
      width: 100px;
    }
    .table-cell-billing-day-suggested {
      width: 130px;
    }
    .table-cell-status-suggested {
      width: 100px;
    }
  }
}
