.g-query-tool {
  .ui.button.orange.active {
    background-color: #fbb700;
  }

  .header-buttons {
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;

      div:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.p-saved-queries-table {
  .p-cell-name {
    width: 160px;
  }
  .p-cell-description {
    width: 160px;
  }
  .p-cell-data-set {
    width: 80px;
  }
  .p-cell-date {
    width: 120px;
  }
  .p-cell-btn {
    width: 115px;
  }
}

.dropdown-mimic {
  width: fit-content;
  width: -moz-max-content;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  padding: 5px 0 3px 0 !important;
  border-radius: 4px;

  .main-dropdown {
    padding: 0 12px 0 12px !important;
    line-height: 2em;
  }

  .right-arrow {
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 11px;
    border-left: 1px solid #dcdcdc;
  }

  .left-arrow {
    padding-top: 2px;
    padding-left: 11px;
    padding-right: 10px;
    border-right: 1px solid #dcdcdc;
  }

  &:not(.disabled):hover {
    cursor: pointer;
    border-color: rgba(34, 36, 38, 0.35);
  }
}

#period-picker {
  min-width: 200px;
  .period-presets {
    max-height: 200px;
    overflow-y: auto;
  }
  .custom-picker {
    min-width: 280px;
  }

  .period-option {
    padding: 8px 13px 5px;
    border-bottom: 1px solid #eee;
    &.custom {
      border-bottom: 0px;
      &:hover {
        background-color: #fff;
        cursor: auto;
      }
    }
    span {
      font-size: 0.85rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 600;
    }
    &:not(.selected) span {
      color: #666;
    }
    &.selected span {
      color: rgba(0, 0, 0, 0.87) !important;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.95);
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.dataset-picker {
  // display: flex;
  // flex-direction: column;
  padding: 0.5em;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .ui.button {
    min-height: unset !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #eaeaea;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #eaeaea;
  }

  .bg-845EC2.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #845ec2 !important;
  }
  .bg-008F7A.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #008f7a !important;
  }
  .bg-FF6F91.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ff6f91 !important;
  }
  .bg-046e5e.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #046e5e !important;
  }
  .bg-D65DB1.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #d65db1 !important;
  }
  .bg-40d6bb.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #40d6bb !important;
  }
  .bg-FF9671.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ff9671 !important;
  }
  .bg-0089BA.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #0089ba !important;
  }
  .bg-ffe11f.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ffe11f !important;
  }
  .bg-c73a5b.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #c73a5b !important;
  }
  .bg-2C73D2.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #2c73d2 !important;
  }
  .bg-00aeff.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #00aeff !important;
  }
  .bg-2f4b7c.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #2f4b7c !important;
  }
  .bg-665191.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #665191 !important;
  }
  .bg-a05195.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #a05195 !important;
  }
  .bg-d45087.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #d45087 !important;
  }
  .bg-f95d6a.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #f95d6a !important;
  }
  .bg-ff7c43.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ff7c43 !important;
  }
  .bg-ffa600.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ffa600 !important;
  }
  .bg-F6906C.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #f6906c !important;
  }
  .bg-97B1AB.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #97b1ab !important;
  }
  .bg-4B4453.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #4b4453 !important;
  }
  .bg-C34A36.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #c34a36 !important;
  }
  .bg-BEF197.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #bef197 !important;
  }
  .bg-B0A8B9.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #b0a8b9 !important;
  }
  .bg-00E8EF.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #00e8ef !important;
  }
  .bg-B1B37B.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #b1b37b !important;
  }
  .bg-FF8592.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ff8592 !important;
  }
  .bg-A9D965.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #a9d965 !important;
  }
  .bg-00AFB7.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #00afb7 !important;
  }

  & > .dataset {
    max-width: 270px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-right: 20px;
    display: flex;
    align-items: flex-start;

    .hierarchy-line-icon {
      margin-top: 7px;
    }

    & > .selector {
      width: 20px;
      height: 20px;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 5px;

      &.selected {
      }
    }

    & > .color {
      margin-right: 5px;
      display: inline-block;
      width: 7px;
      height: 18px;
      border-radius: 2px;
    }

    & > .label {
      color: #333;
      line-height: 1em;
    }
  }
}

.ui.selection.query-dropdown {
  min-height: unset !important;
  min-width: 115px !important;
  padding: 8px 12px 7px 12px !important;
  line-height: 1.3em !important;

  &.min-width-48 {
    min-width: 48px !important;
  }
  &.min-width-95 {
    min-width: 95px !important;
  }

  & > .text {
    position: relative;
    top: 1px;
    margin-right: 20px;
  }

  & > i.dropdown.icon {
    padding: 8px !important;
  }

  & > .menu > .item {
    padding: 8px 12px !important;
  }
}
