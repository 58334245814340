.g-dashboard-summary {
  .summary-container {
    display: flex;
    margin-top: 1em;

    @media (max-width: $responsive-width) {
      flex-direction: column;
    }
    .left {
      width: 50%;

      .ui.card {
        width: 100%;
      }

      @media (max-width: $responsive-width) {
        width: 100%;
      }
    }

    .right {
      width: 50%;
      margin: 0 1em;

      .ui.card {
        width: 100%;
      }

      @media (max-width: $responsive-width) {
        margin: 1em 0;
        width: 100%;
      }
    }
  }
}
