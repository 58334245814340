
/*******************************
             Page
*******************************/
@pageBackground: #fcfcfc;

/*-------------------
       Fonts
--------------------*/

@fontName: 'Avenir';
@fontFace: 'Avenir';
@pageFont: 'Avenir';
@monospace: 'Inconsolata'

@googleFontRequest : 'Inconsolata|@{googleFontName}:@{googleFontSizes}&subset=@{googleSubset}';

/*-------------------
       Base Sizes
--------------------*/

@emSize   : 14px;
@fontSize : 14px;

@miniSize        : (11 / 14);
@tinySize        : (12 / 14);
@smallSize       : (13 / 14);
@mediumSize      : (14 / 14);
@largeSize       : (16 / 14);
@bigSize         : (18 / 14);
@hugeSize        : (20 / 14);
@massiveSize     : (24 / 14);

/*-------------------
     Font Colors
--------------------*/

@linkColor: #44958c;

@darkGreyText: #4f4f4f;
@defaultGreyText: #666;
@lightGreyText: #737373;
@lighterGreyText: #aeaeae;

@grey: @lighterGreyText;

@red: #E05951;
@green: #21ba45;
@orange: #FBB700;
@white: #fff;

@brandGreen: #44958c;
@brandYellow: #FBB700;

@borderColor: #DCDCDC;

@inputBorderColor: #a5a5a5;
@unfocusedInputBorderColor: #dcdcdc;

@placeholderTextColor: #C9C9C9;
