.g-categories {
  // @media (max-width: 992px) {
  //   overflow-x: scroll;
  //   padding-bottom: 1em;
  // }
  .table-container {
    overflow-y: hidden;
  }
  .p-categories-table {
    min-width: 880px !important;
    .table-cell-name {
      width: 200px;
      min-width: 60px;
      white-space: nowrap;
    }
    .table-cell-matches {
      width: 140px;
    }
    .table-cell-settings {
      width: 40px;
    }
  }

  span.inherited {
    display: block;
    width: 100%;
    color: #ccc;
    padding: 0 !important;
    text-align: center;
  }
}

.category-row-has-icon {
  display: flex;
  align-items: center;
  padding-left: 10px;

  .g-editable-text {
    width: calc(100% - 23px) !important;
  }

  i.icon {
    position: relative;
    top: -3px;
  }
}

.category-rules-row {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin: 0 0 1em 0;
  max-width: 100%;
  padding: 1em;

  span {
    padding-left: 0.25em;
    padding-right: 0.25em;
  }

  .g-editable-text {
    padding: 0.5em 0;
  }
}
