.g-changelog {
  .p-changelog-table {
    min-width: 500px !important;
    .table-cell-id {
      width: 100px;
    }
    .table-cell-tx-id {
      width: 300px;
    }
    .table-cell-timestamp {
      width: 150px;
    }
    .table-cell-rules {
      width: 100px;
    }
    .table-cell-settings {
      width: 50px;
    }
    // From merchant name
    .table-cell-category {
      width: 230px;
    }
    .table-cell-amount {
      width: 130px;
    }
    .table-cell-matches {
      width: 100px;
    }
  }

  span.truncate {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: inline-block;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  span.highlight-box {
    padding: 2px 4px;
    border-radius: 3px;
    border: 1px dashed #ccc;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 252px;

    margin-left: 0.5rem;
  }
}
