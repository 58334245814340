#g-stats {
  .stats-header-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;

      .overview-period-picker {
        margin-bottom: 0.5rem;
      }
    }

    @media (max-width: 600px) {
      .stats-checkboxes {
        flex-direction: column;
      }
    }
  }

  #stats-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;

    .ui.fluid.card {
      width: 32%;
      margin: 1em 0;

      @media (min-width: 850px) and (max-width: 1350px) {
        width: 49%;
      }

      @media (max-width: 849px) {
        width: 100%;
      }

      & > .card-header {
        flex-grow: 0;
      }

      & > .content:not(.card-header) {
        padding: 0 0 0 0 !important;
      }

      .pagination-container {
        flex-wrap: wrap;
        .pagination-amount {
          margin-bottom: 0.5rem;
        }
      }

      .ui.pagination {
        border-radius: 3px;
        margin-bottom: 0.5rem;
      }

      .cell-number {
        white-space: nowrap;
        text-align: right !important;

        .g-editable-text {
          height: auto !important;
          span.readonly {
            padding: 0 !important;
          }
        }
      }

      tr.recurring {
        color: #44958c !important;
      }

      tr.excluded {
        color: #e05951 !important;
      }
    }
  }
}
