.g-locked-out {
  background-image: url('../images/bg.jpg');
  background-repeat: repeat;
  background-size: 50px 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow-y: scroll;

  a {
    font-weight: 700;

    &:hover {
      color: #444;
    }
  }

  .g-locked-out-container {
    margin: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .locked-out-content {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 0 4px 3px #eee;
      width: 50%;
      max-width: 400px;
      margin: 4em auto 4em auto;
      padding: 3em;

      &.wider {
        max-width: 440px;
      }

      @media (max-width: $responsive-width) {
        width: 70%;
        padding: 2em 1.5em;
      }

      @media (max-width: 700px) {
        width: 80%;
        padding: 2em 1em;
      }

      @media (max-width: 500px) {
        width: 85%;
        padding: 2em 0.5em;
      }
      @media (max-width: 400px) {
        width: 90%;
        margin: 2em auto;
        padding: 2em 0.5em;
      }

      .locked-out-details {
        display: flex;
        flex-direction: column;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        max-width: 100%;
        padding: 0.7em 0.5em 0.5em 0.7em;
        color: #666;

        .locked-out-row {
          display: flex;
          justify-content: space-between;

          display: block;
          margin-right: 1em;
          flex-grow: 1;
          font-size: 1.1em;
          font-family: 'Inconsolata';

          b {
            font-weight: 500;
            font-size: 1rem;
            font-family: 'Avenir' !important;
          }
        }
      }

      .message {
        text-align: center;
        margin: 0;
      }

      .error {
        text-align: center;
        color: #e05951;
        margin: 0;
        &.login-error {
          margin-top: 8px;
        }
      }

      .locked-out-form {
        width: 100%;
        margin: 1em 0 2em 0;
        display: flex;
        flex-direction: column;

        button {
          margin-top: 1em;
        }
      }

      .forgot {
        width: 100%;
        text-align: right;
      }

      p.register {
        text-align: center;
      }
    }
  }
}
