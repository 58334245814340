.ui.card > .content > .header:not(.ui),
.ui.cards > .card > .content > .header:not(.ui),
.ui.card > .content > .header {
  color: @lighterGreyText;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: @tiny;
  font-family: @pageFont;
  text-align: center;
  margin-top: 2px;
  line-height: normal;
}

.ui.card {
  margin-bottom: 1em;
  margin-top: 0em;
  box-shadow: 0px 1px 0 #eee;
}

.faded {
  border-color: #efefef !important;
  .content p {
    color: #dadada !important;
  }
}
