.g-budget-settings {
  display: flex;

  & > div:first-child {
    width: 50%;
    margin-right: 1em;
  }

  & > div:last-child {
    width: 50%;
  }

  @media (max-width: $responsive-width) {
    flex-direction: column;

    & > div:first-child {
      width: 100%;
      margin-right: 0em;
    }

    & > div:last-child {
      width: 100%;
    }
  }

  .ui.card {
    width: 100%;
  }
}

.collaborators {
  .collaborator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5em 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1em;
    font-weight: 400;

    .info {
      .email {
        font-family: 'Inconsolata';
      }
    }

    .status {
      dispay: inline-block;
      margin-right: 6px;
      padding: 2px 5px;
      border-radius: 3px;
      text-transform: uppercase;
      font-size: 0.85rem;
      font-family: 'Inconsolata';
      font-weight: 300;

      &.invited {
        background-color: #eee;
        color: #737373;
      }
      &.joined {
        background-color: #44958c;
        color: #fff;
      }
    }
  }
}
