.g-net-worth {
  .header-controls {
    align-items: flex-start !important;
  }
  #net-worth-chart {
    min-width: 600px;
    width: 100%;
    padding: 2em 1em 1em 1em;
    border-radius: 0 0 4px 4px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
  }

  .tooltip-data-row {
    padding-left: 3px;
    padding-right: 3px;
  }
  .highlight-title {
    padding-top: 1px;
    font-weight: 600px;
    border-radius: 4px;
    background-color: #f8f8f8;
  }
  .tooltip-label {
    font-weight: bold;
    min-width: 100px;
    max-width: 200px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .skinny-cell {
    min-width: unset !important;
    width: 25px !important;
    max-height: 33px !important;
    padding: 0px 0px 0px 10px !important;
  }
  .row:not(.header) {
    .skinny-cell {
      height: 33px !important;
    }
  }
  .icon-cell {
    overflow-x: visible !important;
    text-align: center;
    padding: 0px 0px 0px 12px !important;

    i.icon {
      margin-right: 6px;
    }
  }
  .net-worth-report {
    .row:not(.header):not(.footer) {
      .cell:not(.icon-cell):not(.skinny-cell) {
        padding: 7px 12px 5px 12px !important ;
        height: 33px !important;
      }
    }
    .row.footer {
      .cell:not(.icon-cell) {
        padding: 7px 12px 5px 12px !important ;
        height: 33px;
      }
    }
  }
  .number-cell {
    display: table-cell;
    font-family: 'Inconsolata';
    text-align: right;
    height: 33px;
    // Editable overrides
    span.readonly {
      padding: 0 12px 0 9px !important;
      position: relative;
      top: 6px;
    }
    span.default-state,
    span.default-state:hover {
      padding: 2px 7px 2px 9px !important;
      display: block;
    }
    input[type='text'],
    input[type='number'] {
      width: 75px;
    }
  }

  .switcher-button {
    background-color: #fff;
    border: 1px solid #dcdcdc;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    padding: 7px 12px 3px 12px !important;
    border-radius: 4px;
    height: 36px;

    &:not(.disabled):hover {
      cursor: pointer;
      border-color: rgba(34, 36, 38, 0.35);
    }
  }
}

.g-networth-modal {
  .asset-row {
    display: flex;
    justify-content: space-between;
    padding: 0.7em 1em 0.5em 1em;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    max-width: 100%;
    color: #919191;
    margin-bottom: 0.7rem;

    .monospace {
      position: relative;
      top: -1px;
      font-size: 1rem;
      font-weight: 800;
      margin-bottom: 2px;
    }
  }
}
