$onboarding-responsive-width: 900px;

.g-onboarding {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  //background-color: #fafafa;
  background-image: url('../images/bg.jpg');
  background-repeat: repeat;
  background-size: 50px 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $onboarding-responsive-width) {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .setup-content {
    display: flex;
    height: 90%;
    max-height: 700px;
    z-index: 11;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 4px 3px #eee;
    width: 98%;
    max-width: $onboarding-responsive-width;

    @media (max-width: $onboarding-responsive-width) {
      flex-direction: column;
      height: 100%;
      max-height: 100%;
      width: 100%;
      overflow-y: scroll;
      border-radius: 0;
      border: none;
    }

    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      border-radius: 5px 0 0 5px;
      background-color: #44958c;
      background-image: url('../images/onboarding-subbg.png');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: contain;
      width: 280px;
      height: calc(100% + 1px);
      color: #fff;
      padding: 3rem 2rem 0 2rem;

      @media (max-width: $onboarding-responsive-width) {
        width: 100%;
        height: auto;
        background-image: none;
        border-radius: 0;
        padding: 2rem;
      }

      .progress-name {
        display: flex;
        justify-content: space-between;
        span {
          display: inline-block;
          text-transform: uppercase;
          font-size: 0.8em;
        }
      }

      h3 {
        color: #fff;
        font-weight: 500;
        padding-top: 3rem;
        font-size: 1.2rem;
        line-height: 1.7rem;

        @media (max-width: $onboarding-responsive-width) {
          padding-top: 1.2rem;
        }
      }

      ul {
        list-style: square;
        margin: 0;
        padding: 1rem;

        li {
          margin-bottom: 0.7rem;
          font-size: 1rem;
          line-height: 1.7rem;
        }
      }

      .footer-meta {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2.25em;

        #mascot {
          background-image: url('../images/mascot.png');
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 10px;
          height: 35px;
          min-width: 35px;
        }

        .subtext {
          display: inline-block;
          font-weight: 200;
          line-height: 1.5em;
          font-size: 0.85em;
        }

        @media (max-width: $onboarding-responsive-width) {
          margin-top: 1rem;
          align-items: center;
          margin-bottom: 0;
        }
      }
    }

    .right-content {
      border-radius: 5px;
      background-color: #fff;
      padding: 0rem 0rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      #main-onboarding-content {
        width: 100%;
        flex-grow: 1;
        overflow-y: auto;
        padding-bottom: 2em;
        padding-top: 0em;

        @media (max-width: $onboarding-responsive-width) {
          padding-bottom: 1rem;
          padding-top: 1rem;
        }

        .main-content {
          max-width: 500px;
          margin: 0 auto;

          @media (max-width: $onboarding-responsive-width) {
            max-width: 100%;
            padding: 2rem;

            .header-container {
              margin-top: 0rem;
              margin-bottom: 1rem;
            }
          }

          .header-container {
            margin: 42px 0 32px 0 !important;
          }

          .step-label {
            font-size: 0.9em;
            color: #cecece;
            font-weight: 500;
            text-transform: uppercase;
          }

          h2 {
            margin-top: 0.2em;
            font-weight: 600;
            color: #5e5e5e;
          }

          h3 {
            margin-top: 2.25em;
            font-weight: 600;
          }

          p {
            margin-bottom: 2em;
            color: #666;
          }
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e6e6e6;
        padding: 1em;
        margin: 0 1em;
        height: 354x;
        min-height: 34px;
        box-sizing: content-box;

        .skip-link {
          color: #cfcfcf;
          font-weight: 500;
          text-transform: uppercase;
          padding: 5px;
          font-size: 0.9em;
        }
      }
    }
  }

  /* Link accounts */

  .account-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #dadada;
    padding: 0.6em 1em;
    margin: 0.5em 0;
    // max-width: 350px;
    width: 100%;

    & .bank-name {
      font-size: 0.9em;
      font-weight: 500;
      color: #8b8b8b;
      text-transform: uppercase;
    }

    & .account-count {
      font-size: 0.9em;
      font-weight: 400;
      color: #55b021;
      font-family: 'Inconsolata';
      text-align: right;
    }
  }

  /* Recurring expenses */
  .setup-recurring-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    max-width: 100%;
    padding: 0.7em 0.5em 0.5em 0.5em;

    &.dismissed,
    &.dismissed > span,
    &.dismissed > div > span {
      color: #dadada !important;
    }

    &:nth-child(even) {
      background-color: #fbfbfb;
    }

    &:hover {
      background-color: #fbfbfb;
    }

    .recurring-payee {
      font-size: 1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .recurring-metadata {
      display: block;
      color: #afafaf;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 0.75em;
    }

    .right-side {
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding-left: 2em;

      .recurring-price {
        font-size: 1em;
        font-weight: 400;
        color: #55b021;
        font-family: 'Inconsolata';
        text-align: right;
        display: inline-block;
        margin-right: 10px;
      }

      i.icon {
        color: #cacaca;
      }

      .undo {
        display: block;
        color: #cacaca;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.75em;
        padding: 2px;
      }
    }
  }
}

.g-onboarding,
.g-categories-setup {
  /* Categories */
  .category-content {
    flex-wrap: wrap;
  }
  .setup-category {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    max-width: 100%;
    padding: 0.7em 0.8em 0.7em 1em;
    margin-bottom: 0.7em;
    color: #919191;
    overflow: hidden;

    .icon {
      color: #e5e5e5;
    }

    &.default {
      color: #666;
      border: 1px solid #666;
      .icon {
        color: #666;
      }

      &:hover {
        cursor: not-allowed;
      }
    }

    &.added {
      border: 1px solid #44958c;
      color: #44958c;

      .icon {
        color: #44958c;
      }
    }

    &:hover {
      cursor: pointer;
    }
    &:hover:not(.added):not(.default) {
      border: 1px solid #919191;
      color: #919191;
      .icon {
        color: #919191;
      }
    }

    .category-payee {
      font-size: 1.1em;
      font-family: 'Inconsolata';
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .category-metadata {
      padding-top: 3px;
      display: block;
      font-weight: 300;
      font-size: 0.9em;
      line-height: 1.3em;
    }

    .right-side {
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding-left: 2em;

      i.icon {
        color: #cacaca;
      }

      .undo {
        display: block;
        color: #cacaca;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.75em;
        padding: 2px;
      }
    }
  }
}

#link-accounts,
#final-onboarding {
  .type-container {
    display: flex;
  }

  .type-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    padding: 1em 2em 1em 0;
  }

  .type-content {
    margin-top: 5px;
  }

  h3 {
    // font-size: 1.2rem;
    // font-weight: 500 !important;
    margin-top: 1rem !important;
    // color: #444;
  }

  p {
    margin-bottom: 1.3rem !important;
  }
}

#setup-preferences {
  .setup-category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75em 0.25em 0.75em 0.25em;
    width: 50%;
    img {
      margin-top: 0.5rem;
      width: 220px;
    }

    span.note {
      margin-top: 0.5rem;
      color: #333;
      font-size: 0.9rem;
      line-height: 1.1rem;
    }

    &:first-child {
      margin-right: 0.25em;
    }

    &:last-child {
      margin-left: 0.25em;
    }
  }
}
