/*******************************
            Button
*******************************/

.ui.button {
  /*line-height: normal;*/
  position: relative;
  transition: all 0.1s ease-in;
  top: 0;
  padding: .75rem 1.2rem .65rem;
}

.ui.button.no-hover:hover {
  top: 0 !important;
}

.ui.button:hover:not(.dropdown) {
  top: -2px;
}

/*-------------------
        Sizes
--------------------*/

.ui.mini.buttons .button,
.ui.mini.buttons .or,
.ui.mini.button {
  // padding: 0.6875em;
}

.ui.tiny.buttons .button,
.ui.tiny.buttons .or,
.ui.tiny.button {
  padding: 2px 5px 3px 5px !important;
  font-size: 0.8rem !important;
  font-family: 'Inconsolata';
  background-color: #fff;
  font-weight: 300;
  color: #ccc;
  border: 1px solid #ddd;

  &.green, &.green:hover, &.green:focus {
    background-color: #21ba45;
    color: #fff;
    border: 1px solid #21ba45;
  }
  &.green-hover:hover, &.green-hover:focus {
    background-color: #21ba45 !important;
    color: #fff;
    border: 1px solid #21ba45;
  }
  &.yellow, &.yellow-hover:hover {
    background-color: #fbb700;
    color: #fff;
    border: 1px solid #fbb700;
  }
  &.red, &.red-hover:hover {
    background-color: #e05951;
    color: #fff;
    border: 1px solid #e05951;
  }
}

.ui.small.buttons .button,
.ui.small.buttons .or,
.ui.small.button {
  // padding: 0.6875em;
}

/*-------------------
        Sizes
--------------------*/

.ui.basic.button.no-border {
  box-shadow: none !important;
}

.ui.basic.button.no-border:hover {
  box-shadow: 0 0 0 1px none inset !important;
  color: none !important;
}
