.display--flex {
  display: flex !important;
}

.display--block {
  display: block !important;
}

.display--none {
  display: none !important;
}

.display--inline-block {
  display: inline-block !important;
}

.flex--column {
  flex-direction: column;
}

.overflow--hidden {
  overflow: hidden;
}

.flex--wrap {
  flex-wrap: wrap;
}

.flex-no-grow {
  flex-grow: 0 !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex--center-align {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex--column-end {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.flex--justify-center {
  display: flex;
  justify-content: center;
}

.flex--start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start !important;
}

.flex--end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.flex--align-end {
  display: flex;
  align-items: flex-end;
}

.align-self-flex--end {
  align-self: flex-end;
}
.align-self-flex--start {
  align-self: flex-start;
}

.flex--column-space-between {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flex--space-between {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.flex--space-between-flex-start {
  display: flex !important;
  align-items: flex-start;
  justify-content: space-between;
}

.flex--space-between-flex-center {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.flex--space-between-flex-end {
  display: flex !important;
  align-items: flex-end;
  justify-content: space-between;
}

.flex--align-top {
  display: flex;
  align-items: flex-start !important;
}

.flex--align-center {
  display: flex !important;
  align-items: center;
}

.flex--align-center-on-mobile {
  display: block !important;
  align-items: center;
}
@media (max-width: 819px) {
  .flex--align-center-on-mobile {
    display: flex !important;
    width: 100%;
    .g-editable-text {
      max-width: 85%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

#sticky {
  margin-bottom: 4rem;
}

.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-1rem {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.padding-bottom-1rem {
  padding-bottom: 1rem !important;
}

.padding-left-10px {
  padding-left: 10px !important;
}

.no-side-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.position--relative {
  position: relative !important;
}

.font--bold {
  font-weight: 600 !important;
}

.font--normal {
  font-weight: 400 !important;
}

.color--green {
  color: #21ba45 !important;
}

.color--grey {
  color: #cacaca !important;
}

.color--dark-grey {
  color: #666 !important;
}

.color--black-text {
  color: #737373 !important;
  font-size: 0.95em;
  font-weight: 400;
}

.color--red {
  color: #e05951 !important;
}

.color--yellow {
  color: #fbb700 !important;
}

.color--white {
  color: #ffffff !important;
}

.color--brand-green {
  color: $brand-green !important;
}

i.dark-grey-on-hover:hover {
  color: #666 !important;
}

.font-size--smaller {
  font-size: 0.9em !important;
}

.font-size--normal {
  font-size: 1em;
}

.font-size--larger {
  font-size: 1.2em !important;
}
.margin-1rem {
  margin: 1rem !important;
}
.margin-0 {
  margin: 0 !important;
}

.margin-5 {
  margin: 0 5px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-05rem {
  display: block;
  margin-top: 0.5rem !important;
}

.ml-05rem {
  display: block;
  margin-left: 0.5rem !important;
}

.ml-1rem {
  margin-left: 1rem !important;
}

.ml-2rem {
  margin-left: 2rem !important;
}

.ml-4rem {
  margin-left: 4rem !important;
}

.mb-05rem {
  display: block;
  margin-bottom: 0.5rem !important;
}

.mb-1rem {
  display: block;
  margin-bottom: 1rem !important;
}

.mb-2rem {
  display: block;
  margin-bottom: 2rem !important;
}

.mb-17rem {
  display: block;
  margin-bottom: 1.7rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-05rem {
  margin-right: 0.5rem !important;
}

.pr-05rem {
  padding-right: 0.5rem !important;
}

.mr-1rem {
  margin-right: 1rem !important;
}

.pr-1rem {
  padding-right: 1rem !important;
}

.mt-1rem {
  display: block;
  margin-top: 1rem !important;
}

.mt-2rem {
  display: block;
  margin-top: 2rem !important;
}

.no-border {
  border: 0px !important;
}

.no-border-bottom {
  border-bottom: 0px !important;
}

.border-bottom {
  border-bottom: 1px solid #dcdcdc;
}

.blank-table-background {
  background-color: #fff !important;
}

.no-border-top {
  border-top: 0px !important;
}

.no-min-height {
  min-height: unset !important;
}

.lower-min-height {
  min-height: 250px !important;
}

.width-100 {
  width: 100% !important;
}

.width-250 {
  width: 250px !important;
}

.max-width-230 {
  max-width: 230px !important;
}

.position-top-1px {
  position: relative;
  top: 1px;
}

.position-top--2px {
  position: relative;
  top: -2px;
}

.position-top-1-5px {
  position: relative;
  top: 1.5px;
}

.height-100 {
  height: 100% !important;
}

.hover--bold:hover {
  font-weight: bold;
}

.hover-toggle {
  width: 100%;

  .hide-on-hover {
    display: block !important;
  }

  .show-on-hover {
    display: none !important;
  }

  &:hover {
    .hide-on-hover {
      display: none !important;
    }

    .show-on-hover {
      &.flex {
        display: flex !important;
      }
      // and not flex
      &:not(.flex) {
        display: block !important;
      }
    }
  }
}

.no-border-radius {
  border-radius: 0px !important;
}

.cell-padding {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.row-padding {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.ui.button.green-on-hover:hover {
  background-color: $green;
  border-color: $green;
  color: $white;
}

.link {
  color: $brand-green !important;
  font-weight: 500;
}

.underline-link {
  color: $brand-green;
  border-bottom: 1px dashed $brand-green;
  padding: 1px 0px !important;

  &:hover {
    cursor: pointer;
    border-bottom-color: rgba(0, 0, 0, 0);
  }
}

.underline-text {
  color: $brand-green;
  border-bottom: 1px dashed $brand-green;
  padding: 1px 0px !important;

  &:hover {
    cursor: default;
    border-bottom-color: rgba(0, 0, 0, 0);
  }

  &.color--red {
    border-bottom-color: $red;
  }
}

// global

ol {
  margin: 0;
  padding-inline-start: 1.25rem;
}

.italic {
  font-style: italic;
}

.unwrap {
  div.text {
    white-space: unset !important;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  overflow-wrap: unset;
}

.ellipsis-table {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.no-scroll {
  overflow: hidden;
}

.right-align {
  text-align: right !important;

  &.ui.input > input {
    text-align: right;
  }
}

.ui.input.p-input-inline:not(.smaller-width) {
  padding: 9px 7px !important;
  width: 100%;
}

.ui.input.p-input-inline > input {
  padding: 0 5.1px !important;
  border: 0px;
}

.left-align {
  text-align: left !important;
}

.center-align {
  text-align: center !important;
}

.border-button {
  display: inline-block;
  color: #666;
  padding: 2px 5px;
  border: 1px solid #666;
  background-color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
}

.clickable:hover {
  cursor: pointer !important;
}
.not-clickable:hover {
  cursor: default !important;
}

/** Mascots */
.g-empty-row {
  background-image: url('../images/light lines bg.png') !important;
  background-size: contain;
  background-repeat: repeat;
  &:hover {
    background-image: url('../images/light lines bg.png') !important;
    background-size: contain;
    background-repeat: repeat;
    background-color: none;
  }
}
div.g-empty-row {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  p {
    font-family: 'Inconsolata';
    font-size: 1rem;
    color: #666;
  }
}
tr.g-empty-row {
  & > td {
    padding: 2em 0 3em 0 !important;

    p {
      max-width: 500px;
      margin: 1em auto 0 auto;
      line-height: 24px;
    }
  }
}

.line-height--normal {
  line-height: normal;
}

.empty-mascot {
  margin: 1em auto;
  display: block;
  background-image: url('../images/mascot-empty.png');
  height: 80px;
  width: 80px;
  background-repeat: no-repeat;
  background-size: contain;
}

.yellow-mascot {
  margin: 1em auto;
  display: block;
  background-image: url('../images/mascot.png');
  &:not(.fitted) {
    height: 80px;
    width: 80px;
  }
  &.fitted {
    height: 100%;
    max-height: 28px;
    width: 29px;
  }
  &.nav {
    height: 36px;
    width: 43px;
    padding-right: 1rem;
  }
  background-repeat: no-repeat;
  background-size: contain;
}

/** Spinning loader button */
.spinner:hover {
  color: #000 !important;
  animation-name: spin;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Bouncing mascot */
#logo-container {
  margin: 1em auto;
  height: 100px;
  width: 100px;
}

#logo {
  background-image: url('../images/mascot.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 75px;
  height: 75px;
  position: relative;
  top: 0px;
  left: 10px;
  animation-name: mascot-bounce;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;

  &.empty-logo {
    background-image: url('../images/mascot-empty.png') !important;
  }
}

#shadow {
  margin: 10px auto;
  width: 50px;
  height: 10px;
  background: rgba(0, 0, 0, 1);
  opacity: 0.5;
  border-radius: 80%;
  animation-name: mascot-shadow;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes mascot-shadow {
  from {
    width: 55px;
    height: 8px;
    opacity: 0.5;
  }
  to {
    width: 30px;
    height: 6px;
    opacity: 0.8;
  }
}

@keyframes mascot-bounce {
  from {
    top: -15px;
  }
  to {
    top: 0px;
  }
}

.divider-line {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ccc;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;

  &:before {
    border-top: 1px solid #dfdfdf;
    content: '';
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: -1;
  }

  span {
    background-color: #fcfcfe;
    padding: 0 5px;
  }
}

.g-global-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3001;

  & > div {
    background-color: rgba(256, 256, 256, 0.95);
    border: 1px solid #dadada;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 200px;

    p {
      font-family: 'Inconsolata', monospace;
      color: #666;
    }
  }
}

.monospace {
  font-family: 'Inconsolata', monospace !important;
}

i.icon.full-width {
  width: 100%;
  text-align: right;
  padding: 1px 5px;
}

.see-more-link {
  font-size: 0.9em;
  display: block;
  text-align: center;
  font-weight: 500;
}

.center-block {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.display-on-mobile {
  display: none !important;
  &:not(.narrow) {
    @media (max-width: 1415px) {
      display: block !important;
    }
  }

  &.narrow {
    @media (max-width: 1050px) {
      display: block !important;
    }
  }
}

.hide-on-mobile {
  &:not(.narrow) {
    @media (max-width: 1415px) {
      display: none !important;
    }
  }

  &.narrow {
    @media (max-width: 1050px) {
      display: none !important;
    }
  }
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #fbb700 !important;
}

// walkthrough stuff

.show-walkthrough-link {
  font-size: 0.8rem;
  padding: 1em;
  font-weight: 600;
  display: block;
  text-align: center;
  color: #cacaca;
  text-transform: uppercase;
}

.border-top {
  margin-top: 0.6rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 0.6rem;
}

// end walkthrough stuff

#budget-onboarding {
  .ui.dropdown {
    font-family: 'Inconsolata';
  }
  label {
    font-size: 0.9em;
    font-family: 'Avenir';
    color: #bbb;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.label-style {
  font-size: 0.9em;
  font-weight: 600;
  display: block;
  text-align: left;
  color: #666;
  text-transform: uppercase;
  padding-top: 2px;
  padding-bottom: 2px;

  &.underlined {
    border-bottom: 1px solid #ddd;
    margin-bottom: 0.5rem;
  }
}

p.divider {
  border-bottom: 1px dashed #dedede;
  width: 90%;
  display: block;
  margin: 1em auto 1em auto !important;
}

p.small {
  font-size: 0.85em;
  display: block;
  text-align: center;
  color: #666;
}

.no-pointer-events {
  pointer-events: none;
}

.editable-price-modal {
  .editable-price-negative {
    border-radius: 4px 0 0 4px;
  }
  .ui.input {
    left: -2px !important;
  }
  .ui.dropdown {
    position: relative;
    left: -3px;
  }
}

.editable-price {
  &.has-dropdown > .ui.input > input {
    border-radius: 4px 0 0 4px;
  }

  &.has-sign-label {
    height: 100%;
    .editable-price-negative {
      background-color: #ececec;
      border: 1px solid #ececec;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 2px;
      width: 20px;
      span {
        font-weight: 600;
        color: #000000;
      }

      &.positive {
        background-color: #55b021;
        border: 1px solid #55b021;
        span {
          text-align: center;
          display: block;
          color: #fff !important;
        }
      }
    }

    &:not(.has-dropdown) {
      .editable-price-negative {
        // margin-top: -1px;
        // margin-bottom: -1px;
      }
    }

    .ui.input.smaller-width {
      width: calc(100% - 65px) !important;

      input {
        border-radius: 0 !important;
      }
    }

    .ui.input:not(.smaller-width) {
      width: calc(100% - 20px) !important;

      input {
        border-radius: 0 4px 4px 0 !important;
      }
    }
  }
}

.strikethrough {
  color: #ccc;
  text-decoration: line-through;
}

.bulk-checkbox {
  display: block;
  margin: 0 auto;
  width: 16px;
  height: 16px;
  &.cta {
    background-image: url('../images/checkbox-empty.svg');
    &:hover {
      background-image: url('../images/checkbox-checked.svg');
    }
  }
  &.empty {
    background-image: url('../images/checkbox-empty.svg');
  }
  &.checked {
    background-image: url('../images/checkbox-checked.svg');
  }
  &.deselect {
    background-image: url('../images/checkbox-deselect.svg');
  }
  &.empty:hover {
    cursor: pointer;
    background-image: url('../images/checkbox-hover.svg');
  }
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.csv-icon {
  width: 36px;
  height: 40px;
  background-image: url('../images/landing-csv.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: relative;
  top: 3px;
}

.mint-logo {
  height: 30px;
  width: 95px;
  background-image: url('../images/mint.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  display: inline-block;
  position: relative;
  top: 6px;
}

.stacked-bar-icon {
  width: 16.5px;
  height: 12px;
  position: relative;
  top: 1px;
  background-image: url('../images/stacked-bar-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;

  &.white {
    background-image: url('../images/stacked-bar-icon-white.svg');
  }
}

.cog-icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/cog.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.in-category-group-icon {
  width: 12px;
  height: 12px;
  background-image: url('../images/in-category-group.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.category-group-icon {
  width: 12px;
  height: 12px;
  background-image: url('../images/category-group.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.multi-select-icon {
  width: 15px;
  height: 12px;
  background-image: url('../images/multiselect-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;

  &.white {
    background-image: url('../images/multiselect-icon-white.svg');
  }
}

.reorder-icon {
  width: 17px;
  height: 17px;
  background-image: url('../images/reorder.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: relative;
  top: 3px;

  &.white {
    background-image: url('../images/multiselect-icon-white.svg');
  }
}

.hierarchy-icon {
  width: 8px;
  height: 12px;
  background-image: url('../images/hierarchy.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  margin-left: 1px;
  margin-right: 7px;
}

// Category row
.hierarchy-line-icon {
  width: 8px !important;
  height: 5px;
  background-image: url('../images/hierarchy-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  margin-left: 5px;
  margin-right: 2px;
  margin-top: -3px;
  padding: 0 8px !important;
}

// Analyze nested category row
.hierarchy-line-icon-a {
  width: 8px !important;
  height: 5px;
  background-image: url('../images/hierarchy-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  top: 1px;
  left: 3px;
  padding: 0 6px !important;
  margin-right: 0.7rem;
}

// EditableCategory
.dropdown-category-group {
  opacity: 1 !important;
  color: #aaa !important;
  background-color: #fbfbfb;
  &:hover {
    background-color: #fbfbfb !important;
  }
}

.hierarchy-line-icon-1 {
  width: 8px !important;
  height: 5px !important;
  box-shadow: none !important;
  background-image: url('../images/hierarchy-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  margin-left: 5px;
  margin-right: 7px;
  margin-top: 0px;
  position: relative;
  top: 2px;
  padding: 0px 8px !important;

  &.multiple {
    top: -4px !important;
  }

  &.in-modal {
    top: 2px !important;
  }
}

// Expanded
.hierarchy-line-icon-2 {
  width: 8px;
  height: 5px;
  background-image: url('../images/hierarchy-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  margin-left: 0px;
  margin-right: 9px;
  margin-top: 1px;
  padding: 0px 8px !important;
}

// Budget row
.hierarchy-line-icon-3 {
  width: 8px;
  height: 5px;
  background-image: url('../images/hierarchy-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  margin-left: 2px;
  margin-right: 7px;
  margin-top: -3px;
  padding: 0px 8px !important;
}

// Budget summary
.hierarchy-line-icon-4 {
  width: 8px;
  height: 5px;
  background-image: url('../images/hierarchy-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  margin-left: 1px;
  margin-right: 7px;
  margin-top: 4px;
  padding: 0px 8px !important;
}

// Spending breakdown
.hierarchy-line-icon-5 {
  width: 8px;
  height: 5px;
  background-image: url('../images/hierarchy-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  margin-left: 4px;
  margin-right: 7px;
  padding: 0px 6px !important;
  top: -3px;
  position: relative;
}

// Linked Accounts
.hierarchy-line-icon-7 {
  width: 8px;
  height: 5px;
  background-image: url('../images/hierarchy-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  margin-left: 4px;
  margin-right: 7px;
  padding: 0px 10px !important;
  top: -3px;
  position: relative;
}

.show-full-on-hover {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    overflow: visible;
    position: relative;
    min-width: 100%;
    width: auto !important;
    z-index: 10;
    background-color: #e6f2f2;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.ui.button.adjust {
  padding: 0.8rem 1.2rem 0.75rem !important;
}

// Autocomplete

// Autocomplete - EditablePrice in Budget

.editable-price-inline-edit {
  .react-autosuggest__input {
    width: 100%;
    border: 0 !important;
  }
  .react-autosuggest__suggestions-container--open {
    width: unset;
    left: -2px !important;
    top: 31px !important;
  }
  .react-autosuggest__suggestions-list {
    span.label-style {
      overflow: visible !important;
      white-space: nowrap;
      width: unset;
      padding-right: 2rem;
      text-transform: unset !important;
    }
    span {
      padding-top: 2px !important;
      padding-bottom: 4px !important;
    }
  }
}

// Autocomplete - EditableString

.g-editable-text.selected:not(.editable-price-inline-edit) {
  .react-autosuggest__suggestions-container--open {
    top: 26px !important;
    left: 0px;
  }
}

.filter-row-value {
  .p-input-modal {
    .react-autosuggest__suggestions-container--open {
      top: 30px !important;
    }
    input.react-autosuggest__input {
      padding: 4px 10px !important;
    }
  }
}

.p-input-modal {
  .react-autosuggest__suggestions-container--open {
    top: 35px !important;

    ul.react-autosuggest__suggestions-list > li {
      font-size: 1.1rem;
    }
  }
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  top: 32px;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 4px;
  z-index: 999;
  box-shadow: 0 2px 3px 0 #dcdcdc;
  max-height: 225px;
  overflow-y: auto;

  ul.react-autosuggest__suggestions-list {
    padding: 0;
    margin: 0;
    li {
      font-family: 'Inconsolata', monospace;
      font-size: 1rem;
      color: #666;
      list-style: none;
      padding: 0.6rem 1rem 0.5rem 1rem;
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: 0px;
      }

      &.react-autosuggest__suggestion--highlighted {
        background-color: #fafafa;
      }
    }
  }
}

.account-icon-letter {
  span.letter {
    color: $white;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: initial;
  }
  span.corner {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $brand-yellow;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    justify-content: center;
    align-items: center;
    display: flex;
    span.corner-number {
      position: relative;
      top: 1px;
      font-weight: bold;
      color: #fff;
      font-size: 11px;
    }
  }
  &.absolute {
    position: absolute;
    left: 0;
    span.letter {
      position: relative;
      top: 1px;
    }
  }
  margin-right: 1rem;
  background-color: $brand-green;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Canny_Badge {
  background-color: #f2711c !important;
  padding: 3px !important;
  top: 6px !important;
  right: -3px !important;
}

// Range
input[type='range'] {
  height: 39px;
  -webkit-appearance: none;
  margin: 0px 0 1rem 0;
  width: 100%;
  border: 0px !important;
  padding: 0px 0 0.8rem 0 !important;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #e8e8e8;
  border-radius: 5px;
  border: 0px solid #e6e6e6;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 3px solid #ffffff;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: $brand-green;
  background-image: url('../images/slider1.svg');
  background-size: 15px 15px;
  background-position: center 2px;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #e8e8e8;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #e8e8e8;
  border-radius: 5px;
  border: 0px solid #e6e6e6;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 3px solid #ffffff;
  height: 21px;
  width: 21px;
  border-radius: 15px;
  background: $brand-green;
  background-image: url('../images/slider1.svg');
  background-size: 15px 15px;
  background-position: center 2px;
  background-repeat: no-repeat;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: $brand-green;
  border: 0px solid #e6e6e6;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-fill-upper {
  background: #e8e8e8;
  border: 0px solid #e6e6e6;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-thumb {
  border: 3px solid #ffffff;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: $brand-green;
  background-image: url('../images/slider1.svg');
  background-size: 15px 15px;
  background-position: center 2px;
  background-repeat: no-repeat;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: $brand-green;
}
input[type='range']:focus::-ms-fill-upper {
  background: #e8e8e8;
}

// dropdown search
// .ui.search.dropdown > input.search {
//   top: 3px !important;
// }

.feedback-dropdown {
  .menu > .item {
    display: block !important;
  }
}
