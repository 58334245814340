/*******************************
             Table
*******************************/

.ui.table {
  // padding: 0 8px;
  box-shadow: 0px 1px 0 #eee;
  // width: auto; // To keep the fixed cell widths
  min-width: 100%;
}

/*******************************
             Parts
*******************************/

/* Headers */
.ui.table thead th, .ui.table.sortable thead th,
.ui.table tfoot th, .ui.table.sortable tfoot th {
  font-size: @tiny;
  letter-spacing: 0.5px;
  padding: 10px 13px 8px 13px;
  color: @lighterGreyText;
  height: 40px;
  line-height: 15px;

  & > div {
    margin-top: 1px;
    display: inline-block;
  }
}

/* Body */
.ui.table tbody {
  font-family: 'Inconsolata';
  font-size: @medium;
  color: @defaultGreyText;
}

/* Alternating stripes */
.ui.table tbody tr:nth-child(2n):not(.g-empty-row):not(.g-loader-row) {
  //background-color: #fcfcfc;
}

.ui.table tr {
  td:first-child {
    // padding-left: 4px;
  }

  td > .show-on-hover, td > .show-on-hover {
    display: none;
  }

  td > .hide-on-hover {
    display: inline-block;
  }
}

.ui.table tr:hover {
  & > td > .show-on-hover {
    display: inline-block;
  }
  & > td > .hide-on-hover {
    display: none;
  }
}

.ui.table tr.editing td {
  // padding: 4px 2px;
}

.ui.table td.number, .ui.table th.number {
  // max-width: 100px;
  text-align: right;

  &.critical {
    color: #e05951;
  }

  &.green {
    color: #55b021;
  }
}

.ui.table td.center, .ui.table th.center {
  text-align: center !important;
}



/*******************************
          Variations
*******************************/

/*
 Selectable
*/
@media only screen and (min-width: 820px) {
  .ui.selectable.table tbody tr:hover:not(.g-empty-row):not(.editing):not(.no-hover-tr) {
    background-color: #fafafa !important;
  }

  .ui.selectable.table tbody tr:not(.g-empty-row):not(.editing):not(.no-hover-tr) td:hover,
  .ui.table tbody tr td.selectable:not(.g-empty-row):not(.editing):not(.no-hover-tr) td:hover {
    i.green-on-hover {
      color: #4f4f4f !important;
    }
  }
}
