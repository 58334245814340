// expanded
#tooltip-budgeted,
// main
#tooltip-bar-0 {
  background: rgba(#28c3d4, 0.9);
  transition: all 0.2s linear;

  &.clickable:hover {
    span.link.clickable {
      border-bottom: 1px solid #fff;
    }
  }
}

// expanded
#tooltip-spent {
  background: rgba(#ee5a5a, 0.9);

  &.clickable:hover {
    span.link.clickable {
      border-bottom: 1px solid #fff;
    }
  }
}

// Trends
#tooltip-bar-1 {
  background: rgba(#ffa41c, 0.9);

  &.clickable:hover {
    span.link.clickable {
      border-bottom: 1px solid #fff;
    }
  }
}

// Trends
#tooltip-0,
#tooltip-1 {
  background: rgba(#2196f3, 0.9);

  &.clickable:hover {
    span.link.clickable {
      border-bottom: 1px solid #fff;
    }
  }
}

// Stats Year
.tooltip-year {
  white-space: normal !important;
  padding: 10px 10px 8px 10px; // 15px;
  min-width: 290px;
  max-width: 290px;
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  .tooltip-data-row {
    padding: 2px 0 0 0;
    .tooltip-circle {
      position: relative;
      top: -1px;
      width: 8px;
      height: 8px;
      border-radius: 10px;
      margin-right: 5px;
    }
  }
  p.header-text {
    padding: 0 0 0.25rem 0 !important;
    margin: 0 0 0.25rem 0 !important;
    font-size: 0.9rem !important;
    font-weight: 700;
    color: #999;
    text-transform: uppercase;
  }
  .footer-text {
    padding-top: 0.25rem;
    a.link {
      text-align: center;
      display: block;
      color: #fff !important;
      background-color: #fbb700;
      border-radius: 4px;
      font-size: 0.8rem !important;
      font-weight: 700;
      text-transform: uppercase;
      padding: 7px 0 7px 0;
      margin: 4px 0 !important;
    }
  }
}

// Trends
#tooltip-1 {
  background: rgba(#ee5a5a, 0.9);

  &.clickable:hover {
    span.link.clickable {
      border-bottom: 1px solid #fff;
    }
  }
}

// Expanded
p.expanded-header {
  font-size: 0.8em !important;
  font-weight: 800;

  padding: 8px 8px 0px 8px;
  text-transform: uppercase;
}
.expanded-tooltip-row {
  display: block;
  padding: 0 8px 4px 8px;
}

// Trends
.trends-tooltip-row {
  display: block;
  font-size: 13px !important;
  padding: 0 8px 4px 8px;

  &.less-padding {
    padding: 1px 8px 1px 8px !important;
  }
}

// Chart js tooltips
.chartjs-tooltip {
  display: none;
  opacity: 1;
  position: absolute;
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  // padding: 6px 10px;
  white-space: nowrap;
  z-index: 3;

  .tooltip-scrollable {
    padding-bottom: 5px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fafafa;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #eaeaea;
    }
  }

  span.link,
  a:not(.ellipsis) {
    display: block;
    margin-top: 2px;
    font-size: 0.85em;
    color: #fff !important;
    font-weight: 600;
    line-height: 1em;

    &:hover {
      color: #fff;
    }
  }

  p.header {
    font-size: 0.8em !important;
    font-weight: 800;

    padding: 10px 10px 0px 10px;
    text-transform: uppercase;
  }

  .query-tooltip-row {
    padding: 0px 8px 2px 8px;
    align-items: flex-end;

    &:hover:not(.no-hover) {
      background-color: #fff;
    }
  }

  span.text {
    font-size: 13px;
  }

  span.label {
    padding-right: 12px;
    font-size: 13px;
  }

  span.divider {
    border-top: 1px solid rgba(#fff, 0.7);
    padding-top: 3px;
    margin-top: 3px;
  }
}

// Net worth tool tip

#net-worth-tool-tip {
  // pointer-events: none;
  transition: all 0.1s ease-in;
  display: none;
  z-index: 999;
  position: absolute;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;

  .tooltip-scrollable {
    padding: 0px 15px 10px 15px;
    max-height: 250px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #eaeaea;
    }
  }

  .row {
    margin-top: 2px;
    margin-bottom: 4px;

    &.highlight {
      background-color: yellow;
    }
  }

  .padded {
    padding: 8px 10px 6px 10px;
  }

  .header {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 700 !important;
    color: #999;
    text-transform: uppercase;
  }

  .number {
    position: relative;
    top: 0px;
    font-size: 1rem;
    font-weight: 800;
    margin-left: 1rem;
    margin-bottom: 2px;
  }

  .overline {
    border-top: 1px solid #ddd;
    padding-top: 7px;
    margin-top: 4px;
  }
}

// New Query tool
#query-tool-tip {
  // pointer-events: none;
  transition: all 0.1s ease-in;
  display: none;
  z-index: 999;
  position: absolute;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;

  .tooltip-scrollable {
    padding: 0px 15px 10px 15px;
    max-height: 250px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #eaeaea;
    }
  }

  .row {
    margin-top: 2px;
    margin-bottom: 4px;
  }

  .header {
    padding: 10px 15px 8px 15px;
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: 700;
    color: #999;
    text-transform: uppercase;
  }

  p.circle {
    width: 7px;
    margin-bottom: 0;
    margin-right: 8px;
    border-radius: 2px;
  }

  .label {
    color: #333;
    max-width: 250px;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 0.9rem;
    margin-top: 1px;
    color: #666;
    font-weight: 600;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  .tx {
    margin-bottom: 0;
    color: #666;
    font-size: 0.9rem;
  }

  .number {
    position: relative;
    top: -1px;
    font-size: 1rem;
    font-weight: 800;
    margin-bottom: 2px;
  }
}

#query-vertical {
  z-index: -1;
  border: 2px solid #eee;
  transition: all 0.1s ease-in;
  position: absolute;
  width: 1px;
  background-color: #eee;
}
