.g-rules,
.g-rules-modal,
.g-recurring-modal,
.g-auto-categories,
.g-rules-unsubscribe {
  .p-rules-container {
    width: 100%;
    margin-top: 18px;
    display: flex;
    padding-bottom: 2rem;
    overflow-x: auto;

    .rules-container-left {
      padding-right: 0.5em;

      table {
        width: 200px;

        tr.highlighted {
          background-color: lighten($brand-green, 48%) !important;
        }
        td {
          font-family: 'Avenir';

          span {
            position: relative;
            top: 2px;
          }
        }
      }
    }

    .rules-container-right {
      min-width: 900px;
      padding-left: 0.5em;
      // padding-top: 0.5rem;
      flex-grow: 1;
    }

    @media (max-width: 992px) {
      overflow-x: scroll;
      padding-bottom: 1em;
    }
  }

  .p-rules-table {
    .table-cell-name {
      width: 200px;
    }
    .table-cell-toggle {
      width: 75px;
    }
    .table-cell-toggle {
      width: 50px;
    }
    .table-cell-priority {
      width: 100px;
    }
    .table-cell-description {
      min-width: 300px;
    }
    .table-cell-arrow {
      width: 55px;
      padding-right: 12px;
      text-align: right !important;
    }
    .table-cell-settings {
      width: 100px;
    }
    .table-cell-if-then {
      width: 40px;
      text-align: center;
    }
    .table-cell-date {
      width: 88px;
      padding-top: 7px;
      text-align: center;
    }
  }
  .p-rules-table.tight {
    .hang-right {
      .display--flex,
      .flex--align-center {
        justify-content: flex-end;
      }
    }
    .hang-left {
      .flex--align-center {
        justify-content: flex-start;
      }
    }
    .sub-row-a {
      width: unset !important;
    }
  }

  .rules-toolbar {
    @media (max-width: 670px) {
      flex-direction: column;
      align-items: flex-start;
      & .buttons {
        margin-top: 0.5rem;
        @media (max-width: 470px) {
          flex-wrap: wrap;
          justify-content: flex-start;
          // I'm not proud of this
          .ui.button {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  div.border {
    border: 1px solid #ddd;
    padding: 1em;
    margin: 1em;
    border-radius: 4px;
    text-align: center;

    div {
      justify-content: center;
      align-items: center;
    }
  }
  span {
    &.block {
      display: block !important;
    }

    &.highlight {
      font-weight: 600;
      line-height: 1.5rem;
    }

    &.highlight-box {
      padding: 2px 4px;
      border-radius: 3px;
      border: 1px dashed #ccc;

      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 252px;

      position: relative;
      top: 3px;
    }

    &.sub-row-a {
      text-align: right;
      display: inline-block;
      width: 126px;
      margin-right: 10px;
    }

    &.sub-row-b {
      display: inline-block;
      width: 80px;
      text-align: center;
      margin: 0 10px;
    }

    &.sub-row-c {
      display: inline-block;

      &.flex--align-center {
        .highlight-box {
          top: 0px !important;
        }
      }
    }
  }
}

.rules-filter-popup {
  .top-border {
    border-top: 1px solid #ccc;
  }

  .rules-filter-section {
    margin: 0.5em 1em;

    .ui.checkbox {
      margin-bottom: 0.25em;
    }

    h3 {
      color: #aaa;
      font-weight: 600;
      text-transform: uppercase;
      font-family: 'Avenir';
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.6em;
      font-size: 1em;
      // text-transform: uppercase;
      align-items: flex-start !important;
    }
  }
}

#criteria-amount-parent,
#criteria-amount-2-parent {
  .p-input-modal {
    input {
      width: 100% !important;
    }
  }
  input.search {
    width: 100% !important;
  }
}

.criteria-day {
  min-width: unset !important;
}

.apply-rules-modal {
  .apply-rules-table {
    td {
      overflow: hidden;
      &:not(.no-padding) {
        padding: 0 14px !important;
      }
      span {
        white-space: nowrap;
      }
    }
  }

  @supports (position: sticky) {
    thead.sticky th {
      top: -22px;
    }
  }

  td > span {
    padding: 0 !important;
  }
  .cell-toggle {
    width: 75px;
  }
  .cell-date {
    width: 115px;
  }
  .cell-category {
    width: 140px;
  }
  .cell-payee {
  }
  .cell-amount {
    width: 130px;
  }
  .cell-notes {
    width: 200px;
  }
  .cell-tags {
    width: 100px;
  }
  /* Suggested recurring transaction */
  tr.suggested-recurring {
    font-style: italic;
    color: #44958c;

    & .suggested-recurring-button {
      color: #44958c !important;
    }
  }

  /* Recurring transaction */
  tr.recurring {
    color: #44958c;

    & .recurring-button {
      color: #44958c !important;
    }
  }
}
