.g-dashboard {
  .summary-container {
    display: flex;
    margin-top: 1em;
    margin-bottom: 3rem;

    @media (max-width: 900px) {
      flex-direction: column;
    }
    .left-container {
      display: flex;
      flex-grow: 1;

      @media (max-width: 1300px) {
        flex-direction: column-reverse;
        margin-right: 1rem;
      }

      & > .left {
        width: 380px;
        margin-left: auto;
        margin-right: auto;
        min-width: 400px;

        @media (max-width: 1600px) {
          min-width: 320px;
        }
        @media (max-width: 1300px) {
          min-width: 300px;
        }

        .ui.card {
          width: 100%;

          .account-sub-name {
            color: #8d8d8d;
          }

          .account-sub-amount {
            color: #8d8d8d;
          }
        }

        @media (max-width: 1300px) and (min-width: 1200px) {
          display: flex;
          align-items: flex-start;
          width: 100%;
          .ui.card {
            &:first-child {
              margin-right: 1rem;
            }
            width: 50%;
          }
          margin-bottom: 1rem;
        }

        @media (max-width: 1200px) {
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      & > .center {
        // width: 50%;
        flex-grow: 1;
        margin: 0 1em;

        .ui.card {
          width: 100%;
        }

        @media (max-width: 1300px) {
          margin: 0 0 1em 0;
          width: 100%;
        }
      }
    }

    & > .right {
      width: 260px;

      .ui.card {
        width: 100%;
      }

      @media (max-width: 900px) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;
      }
    }
  }
}
