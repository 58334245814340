#g-trends {
  .stats-header-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;

      .overview-period-picker {
        margin-bottom: 0.5rem;
      }
    }

    @media (max-width: 600px) {
      .stats-checkboxes {
        flex-direction: column;
      }
    }
  }

  #year-stats-container {
    margin-top: 1rem;
    .ui.fluid.card {
      width: 100%;

      .g-editable-text {
        width: 100%;
      }
    }

    @media (max-width: 550px) {
      .stats-select-header {
        flex-direction: column;
        align-items: flex-start;
        p {
          margin-bottom: 0 !important;
        }
      }
    }
    .stats-year-chart-container {
      margin-top: 1rem;
      height: 300px;
      width: 100%;
      @media (max-width: 477px) {
        overflow-x: scroll;
      }
      .stats-year-chart {
        height: 100%;
        @media (max-width: 477px) {
          min-width: 400px;
        }
      }
    }
  }

  #small-stats-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 0rem;

    .large-type {
      text-align: center;
      padding-top: 0.5rem;
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 0 !important;
      text-overflow: clip;
      overflow: hidden;
    }

    .sub-type {
      text-align: center;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }

    .ui.fluid.card {
      width: 24%;
      margin: 1em 0 0 0;

      @media (min-width: 800px) and (max-width: 1350px) {
        width: 49%;
      }

      @media (max-width: 799px) {
        width: 100%;
      }

      & > .card-header {
        flex-grow: 0;
      }

      & > .content:not(.card-header) {
        padding: 0 0 0 0 !important;
      }
    }
  }
}
