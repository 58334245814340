.g-linked-accounts {
  .p-accounts-table {
    .table-cell-display-name {
      // width: 180px;
    }
    .table-cell-balance {
      width: 125px;
    }
    .table-cell-status {
      width: 125px;
    }
    .table-cell-last-updated {
      width: 125px;
    }
    .table-cell-settings {
      width: 40px;
    }
  }
}

.g-linked-accounts {
  .cell-metadata {
    span.primary {
      display: block;
      padding: 0;
      margin-bottom: 2px;
    }

    span.secondary {
      font-size: 0.8em;
      font-family: $font-sans-serif;
      color: #ababab;
      .darker {
        color: #999;
        margin-left: 2px;
      }
      font-weight: 500;

      &.circle-label,
      &.text-label {
        overflow: visible;
        text-transform: uppercase;
        padding: 1px 3px 0px 3px;
        font-weight: 600;
        font-size: 0.85em;
        border-radius: 3px;
      }
    }
  }
}

.g-transactions {
  .cell-metadata {
    display: flex;
    flex-direction: column;

    span.primary {
      display: block !important;
      padding: 0;
      margin-bottom: 2px;
    }

    span.secondary {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-top: 1px;
      font-size: 0.8em;
      font-family: $font-sans-serif;
      color: #ababab;
      .darker {
        width: unset !important;
        color: #999;
        margin-left: 2px;
      }
      font-weight: 500;

      &.circle-label,
      &.text-label {
        overflow: visible;
        text-transform: uppercase;
        padding: 1px 3px 0px 3px;
        font-weight: 600;
        font-size: 0.85em;
        border-radius: 3px;
      }
    }
  }
}
