.ungroup-files-table {
  td {
    padding: 0 14px !important;
  }
}

// For files
.modal-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 50px;

  img {
    z-index: 10;
  }
  .modal-image-loader {
    position: absolute;
    left: calc(50% - 0.5rem);
    top: 1rem;
    z-index: 0;
  }
}

.file-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #ddd;
  margin: 1rem 0;
  padding: 0.6rem 0.2rem 0.6rem 0.6rem;
  background-color: #fff;

  .file-box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    i.icon {
      padding: 0 0 0 0.5rem;
      font-size: 1.2em !important;
    }
  }
  .file-box-content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    .file-box-content-filename {
      &:hover {
        cursor: pointer;
        color: $brand-green !important;
      }
    }
    .file-box-content-meta {
      font-size: 0.9rem !important;
      font-family: 'Inconsolata', monospace;
    }
  }
  .options {
    display: flex;
    border-radius: 25px;
    padding: 0.5rem 0.3rem 0.5rem 0.6rem;
    i.icon {
      height: unset !important;
    }
  }
}

#file-drop {
  width: 100%;
  // height: 60px;
  border-radius: 4px;
  border: 1px dashed #dddddd;
  margin: 1rem 0;
  padding: 1rem;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;

  .file-btn {
    background-color: $brand-yellow;
    border-radius: 4px;
    padding: 0.5rem 1.5rem 0.4rem 1.5rem;
    color: #fff !important;
    font-size: 0.9rem;

    &:hover {
      cursor: pointer;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-top: 0.5rem;
      font-family: 'Inconsolata', monospace;
    }
    input[type='file'] {
      display: none;
    }
  }
}
