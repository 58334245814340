.g-loading {
  background-image: url('../images/bg.jpg');
  background-repeat: repeat;
  background-size: 50px 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow-y: scroll;

  a {
    font-weight: 700;

    &:hover {
      color: #444;
    }
  }

  .container {
    margin: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 0 4px 3px #eee;
      width: 50%;
      max-width: 400px;
      margin: 4em auto 4em auto;
      padding: 3em;
      display: flex;
      flex-direction: column;

      @media (max-width: $responsive-width) {
        width: 70%;
        padding: 2em 1.5em;
      }

      @media (max-width: 700px) {
        width: 80%;
        padding: 2em 1em;
      }

      @media (max-width: 500px) {
        width: 85%;
        padding: 2em;
      }
      @media (max-width: 400px) {
        width: 85%;
        margin: 2em auto;
        padding: 2em;
      }

      .progress {
        margin-top: 1rem;
        margin-bottom: 1rem;
        .bar {
          background-color: #55b021;
        }
      }

      p {
        font-family: 'Inconsolata';
        font-size: 1rem;
        text-align: center;
        font-weight: normal;
        color: #eee;
        margin-bottom: 1rem;
        color: #666;
      }
    }
  }
}
