#g-footer-fixed {
  position: fixed;
  bottom: 0.25em;
  right: 0em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem 0 1rem 1rem;
  z-index: 999;

  &.with-banner {
    bottom: 5.5em;
  }

  &:hover {
    // #g-feedback,
    // #g-help,
    // #g-feature {
    //   display: block;
    // }
    //
    // #g-aye {
    //   display: none;
    // }
  }
}

#g-aye {
  padding: 0.6em 0.75em 0.8em !important;
}

// #g-help,
// #g-feedback,
// #g-feature {
//   display: none;
// }

#g-help,
#g-feature {
  margin-bottom: 0.5rem;
}

.rating-box {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: #f9fafb;
  padding: 5px 2px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #ddd;
  border-top: 0px;

  div.rating-label {
    font-size: 0.85rem;
  }
}

.footer-btn {
  color: #666;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 4px 0 0 4px;
  padding: 0.6em 0.75em;
  span {
    position: relative;
    top: 0px;
    margin-left: 4px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9rem;
  }

  &:not(.no-hover):hover {
    border-color: #666;
    cursor: pointer;
    color: #000;
    top: -3px;
  }
}

.submit-feedback-text {
  span {
    display: inline-block;
    margin-left: 0.5em;
  }
}

.submit-feedback-form {
  form .fields {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  form .fields > textarea {
    font-size: 1em;
  }
}
