.g-calendar {
  .p-calendar-table {
    min-width: 1000px !important;
  }

  td.cell-day {
    position: relative;

    min-width: 180px;
    &:not(.null-day):hover {
      background-color: #e6f2f0;
    }
    min-height: 1px;
    vertical-align: top;

    &.is-today {
      .display-day {
        // background-color: lighten($brand-green, 48%);
        background-color: $brand-green;
        border-radius: 20px;
        padding: 5px 5px 3px 5px;
        position: relative;
        top: -5px;
        left: -5px;
        color: #fff !important;
        min-width: 28px;
        text-align: center;
      }
    }
    &:not(:first-child) {
      border-left: 1px solid #e6e6e6 !important;
    }

    .cell-day-container {
      padding: 10px 10px 39px 10px !important;
      height: 100%;
      min-height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .display-day {
        font-family: 'Avenir';
        font-weight: bold;
        color: #999;
      }
    }
    .cell-day-total {
      position: absolute;
      bottom: 0;

      width: 100%;

      .total {
        padding: 7px 0 4px 0;
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 10px;
        border-top: 1px solid #e6e6e6;
      }
    }
  }

  .calendar-item {
    &:hover {
      cursor: default;
    }
  }
}

.line-item {
  display: flex;
  justify-content: space-between;
  margin: 1px 0px; //3px 0 3px 0;
  padding: 1px 0 1px 0;

  &:hover {
    cursor: default;
  }

  .cadence-yearly,
  .cadence-twice-a-year,
  .cadence-every-4-months {
    &.key::before {
      border-color: #a82e63 !important;
    }

    &.key {
      color: #a82e63 !important;
    }
  }
  &.matched > .cadence-yearly,
  &.matched > .cadence-twice-a-year,
  &.matched > .cadence-every-4-months {
    &.key::before {
      background-color: #a82e63 !important;
    }
  }

  .cadence-every-3-months,
  .cadence-every-2-months,
  .cadence-monthly {
    &.key::before {
      border-color: #7472c3 !important;
    }

    &.key {
      color: #7472c3 !important;
    }
  }
  &.matched > .cadence-every-3-months,
  &.matched > .cadence-every-2-months,
  &.matched > .cadence-monthly {
    &.key::before {
      background-color: #7472c3 !important;
    }
  }

  .cadence-twice-a-month,
  .cadence-every-2-weeks,
  .cadence-once-a-week {
    &.key::before {
      border-color: #00a9ba !important;
    }

    &.key {
      color: #00a9ba !important;
    }
  }

  &.matched > .cadence-twice-a-month,
  &.matched > .cadence-every-2-weeks,
  &.matched > .cadence-once-a-week {
    &.key::before {
      background-color: #00a9ba !important;
    }
  }

  .cadence-custom {
    &.key::before {
      border-color: #129278 !important;
    }

    &.key {
      color: #129278 !important;
    }
  }

  &.matched > .cadence-custom {
    &.key::before {
      background-color: #129278 !important;
    }
  }

  .key {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // margin-right: 10px;

    &:not(.no-label)::before {
      content: '';
      width: 7px;
      height: 7px;
      display: inline-block;
      margin-right: 6px;
      border-radius: 3px;
      margin-bottom: 1px;
      border-width: 1px;
      border-style: solid;
    }
  }

  .underline {
    border-bottom: 1px dashed #ccc;
    margin: 0 5px;
    flex-grow: 1;
    position: relative;
    bottom: 2px;
    &.in-popup {
      bottom: 6px !important;
    }
  }

  .property {
    max-width: 200px;
    white-space: nowrap;
    &:not(.no-truncate) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.ui.popup {
  .line-item > .key {
    font-weight: 600;
  }
}
