.not-sortable-th {
  &:hover {
    background-color: #fff !important;
    cursor: default !important;
  }
}

div.mobile-curtain {
  display: none;
}
.show-on-mobile {
  display: none !important;
}

.ui.table td span {
  overflow: hidden;
}

th {
  overflow: hidden !important;
}

tr.dragged-row {
  border: 1px dashed #ccc !important;
  border-radius: 3px;
  background-color: #f9f9f9;
  td {
    border-top: none !important;
  }
}

.ui.table tfoot th {
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.expandable.number:hover {
  .readonly {
    justify-content: flex-end;
  }
}
td.expandable:hover {
  &.clickable:not(.editable) {
    border-top: none !important;
    .g-editable-text {
      // min-height: 38px;
    }
  }
  .g-editable-text {
    height: auto !important;
    min-height: 37px;
    position: relative;
    top: -0.5px;
    border: none !important;
    overflow: visible !important;
    .readonly {
      position: absolute;
      display: flex !important;
      align-items: center;
      width: calc(100% + 2px) !important;
      margin-left: -1px !important;
      white-space: normal;
      overflow-wrap: anywhere;
      overflow: visible;
      height: auto !important;
      min-height: 39px; // 2 borders
      padding-top: 0.55rem;
      padding-bottom: 0.5rem;
      // line-height: 1.3rem;
      border-radius: 2px;
      background-color: #fafafa !important; // uneditable row hover color
      border: 1px solid #e9e9e9;
    }
  }
}

.editable:hover {
  &.no-wrap-on-hover {
    .g-editable-text:not(.selected) > .default-state {
      white-space: nowrap !important;
    }
  }

  .g-editable-text:not(.selected) {
    height: auto !important;
    position: relative;
    border: none !important;

    .default-state {
      white-space: normal;
      overflow-wrap: anywhere;
      overflow: visible;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      // line-height: 1.3rem;
      border-radius: 2px;
      background-color: #fff;
      border: 1px solid #f8b500;
      z-index: 99;
    }

    &:not(.net-worth-cell) {
      min-height: 37px;
      & > .default-state {
        position: absolute;
        height: auto !important;
        min-height: 37px;
      }
    }
  }
}

.ui.table tbody td.editable .g-editable-text:hover,
div.number-cell.editable .g-editable-text:hover {
  cursor: text !important;
  border-radius: 2px !important;
  border: 1px solid #f8b500;
  background-color: #fff;
  & > .default-state {
    padding-left: 12px !important;
    padding-right: 12px !important;
    color: #f8b500 !important;
  }
}

.g-editable-text.selected {
  border-radius: 2px;
  border: 1px solid #f8b500;
  background-color: #fff !important;
}

tr.selected-row {
  background-color: #fff8e5 !important;
}

thead.sticky th:not(.td-resize) {
  background-color: #fbfbfb !important;
  position: relative;
  min-width: 10px;
}

tr.padded-row td {
  padding: 10px 13px 10px 13px !important;
}

tr.padded-row.highlighted {
  background-color: #fafafa;
  font-weight: bold !important;
}

td.padded-cell {
  padding: 8px 13px 8px 13px !important;
}

tr.fixed-height,
td.fixed-height {
  height: 38px !important;
}

td.padded-cell-lr {
  // padding: 10px 13px 10px 13px !important;
  padding: 0px 13px 0px 13px !important;
}

@supports (position: sticky) {
  thead.sticky th {
    &:not(.td-resize) {
      z-index: 98 !important;
    }
    &.td-resize {
      z-index: 99 !important;
    }
    position: sticky !important;
    top: 0px;
  }
}

.td-resize {
  user-select: none;
  width: 1px;
  padding: 0px !important;
  background-color: #eeeeee !important;
  overflow: visible !important;

  &:not(.no-hover):hover {
    cursor: ew-resize !important;
    background: #eee !important;
  }

  .dragger {
    &:hover {
      cursor: ew-resize !important;
      background: #eee !important;
    }

    background: url('../images/column-resizer.svg') repeat-y !important;
    background-position: center center;
    width: 7px;
    position: absolute;
    height: 100%;
    top: -1px;
    left: -3px;
    z-index: 100;
  }
}

.td-divider {
  user-select: none;
  width: 1px;
  padding: 0px !important;
  background-color: #eee;
}

// Override from tables.less
@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) tbody,
  .ui.table:not(.unstackable) tr {
    display: flex !important;
    flex-direction: column !important;
    border-top: 1px solid #eee;
    td {
      border-top: 0px !important;
      padding: 0px !important;
    }
  }
}

@media only screen and (max-width: 819px) {
  .ui.table:not(.unstackable) tbody,
  .ui.table:not(.unstackable) tr {
    display: flex !important;
    flex-direction: column !important;
    border-top: 1px solid #eee;
    td {
      border-top: 0px !important;
      padding: 0px !important;
    }
  }

  .ui.table:not(.unstackable) {
    min-width: unset !important;

    thead {
      display: none !important;
    }

    // tbody,
    // tr {
    //   // position: relative;
    //   // width: 100%;
    //   display: flex !important;
    //   flex-direction: column !important;
    //   // flex-wrap: wrap;
    //
    //   .ui.table:not(.unstackable) tr > td,
    //   .ui.table:not(.unstackable) tr > th {
    //     // width: 50%;
    //   }
    // }

    tr {
      position: relative;
      // display: flex !important;
      // flex-direction: column !important;
    }

    .mobile-curtain {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: auto;
      z-index: 100;
      &:hover {
        cursor: pointer;
      }
    }

    .review-curtain {
      display: block;
      position: absolute;
      top: 0;
      right: 33px;
      z-index: 150;
      width: 40px;
      height: 100%;
      pointer-events: auto;
      &:hover {
        cursor: pointer;
      }
    }

    tr.uncleared {
      background-image: url('../images/light-uncleared.svg') !important;
      background-size: 37px;
      background-position: right 1.4rem center !important;
      background-repeat: no-repeat !important;
      &:hover {
        background-image: url('../images/light-uncleared.svg') !important;
        background-size: 37px;
        background-position: right 1.4rem center !important;
        background-repeat: no-repeat !important;
      }
    }

    tr.cleared {
      background-image: url('../images/light-cleared.svg') !important;
      background-size: 37px;
      background-position: right 1.4rem center !important;
      background-repeat: no-repeat !important;
      &:hover {
        background-image: url('../images/light-cleared.svg') !important;
        background-size: 37px;
        background-position: right 1.4rem center !important;
        background-repeat: no-repeat !important;
      }
    }

    tr {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
      padding-left: 0.75rem !important;
    }

    td {
      .g-editable-text,
      .g-editable-text .default-state {
        height: auto !important;
        padding: 3px 4px !important;
      }
      .g-editable-text .readonly {
        padding: 3px 4px !important;
      }
      .g-editable-text .empty-state {
        background: none !important;
      }
      .show-on-mobile {
        display: block !important;
      }
      &.hide-on-mobile-td {
        display: none !important;
      }
      &:first-child {
        padding-left: 1px !important;
      }
      padding: 0rem !important;
      // padding-bottom: 0rem !important;
      &.no-data {
        display: none !important;
      }
      &.number {
        text-align: left;
      }
    }
  }
}

#alignment-modal {
  .table-cell-checkbox {
    width: 78px;
  }

  tbody tr td .ui.fitted.checkbox {
    display: flex;

    label {
      margin-bottom: 0 !important;
    }
  }
}
