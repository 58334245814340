.ui.popup.walkthrough {
  background: #44958c !important;
  color: #fff !important;
  border: none !important;
}

.ui.popup.walkthrough:before {
  background: #44958c !important;
  box-shadow: none !important;
}
