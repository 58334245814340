.query-tool-container {
  position: relative;
  margin-top: 1em;

  @media (max-width: 840px) {
    overflow-x: auto;
    padding-bottom: 1em;
  }

  .header-controls {
    min-width: 600px;
    display: flex;

    @media (min-width: 840px) {
      justify-content: space-between;
    }
    align-items: center;
    border-top: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    background-color: #fafafa;
    border-radius: 4px 4px 0 0;
    padding: 7px 10px;

    .icon-container {
      padding: 7px 0 7px 7px;
    }

    .ui.selection.dropdown .text {
      font-size: 0.85rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: #rgba(0, 0, 0, 0.87);
      font-weight: 600;
    }
  }

  .loading-card {
    border: 1px solid #dcdcdc;
    border-radius: 0 0 4px 4px;
    padding: 3rem;
    background-color: #ffffff;
  }

  #query-tool-chart {
    min-width: 600px;
    padding: 2em 1em 1em 1em;
    border-radius: 0 0 4px 4px;
    border: 1px solid #dcdcdc;
    min-height: 400px;

    .chart-background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #fff;
    }

    .pie-chart-rel {
      margin-bottom: 2em;

      canvas {
        width: 64% !important;
        height: 64% !important;
      }
    }
  }

  .tabular-report:not(.net-worth-report) {
    .cell {
      padding: 8px 12px 5px 12px;
    }
  }

  .tabular-report {
    min-width: 600px;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;

    .report {
      display: flex;
      width: 100%;

      & > .left {
        border: 1px solid #dcdcdc;
        border-radius: 0;
        border-right: 1px solid #dcdcdc;

        .row.header {
          border-radius: 4px 4px 0 0;
        }
        .row.footer .cell {
          font-weight: 600;
        }
        .row.secondary .cell {
          text-align: right;
        }
        .hierarchy-line-icon {
          padding-left: 9px;
        }
        .row.footer .cell {
          height: 34px !important;
        }
        .cell {
          max-width: 300px;
          // display: block;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
          font-family: 'Avenir', sans-serif;
          text-align: left;
          font-size: 0.95em;
          color: #333;
          font-weight: 400;
        }
      }

      & > .center {
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-x: scroll;

        .center-table {
          display: table;
          width: 100%;

          .col {
            display: table-column;

            &.header .cell {
              border-radius: 0;
              text-align: right;
            }

            .cell {
              min-width: 100px;
              flex-grow: 1;
            }
          }

          .row {
            &.header .cell {
              border-radius: 0;
              text-align: right;
            }

            .cell {
              min-width: 100px;
              flex-grow: 1;
            }
          }

          .row:not(.header) {
            &:hover {
              background-color: unset;
              .cell,
              .number-cell {
                background-color: #e6f2f2;
              }
            }
          }
        }
      }

      & > .right {
        border-top: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        border-radius: 0;

        .row {
          &.header .cell {
            text-align: right;
          }

          .cell {
            border-radius: 4px;
          }

          &.footer {
            .cell {
              border-radius: 0px;
            }
          }
        }
      }

      .cell {
        display: table-cell;
        white-space: nowrap;
        a:not(.color--green) {
          color: rgba(0, 0, 0, 0.8);
        }
      }

      .row.header {
        height: 38px !important;
        .active-sort {
          color: #333 !important;
        }

        .cell {
          padding: 12px 12px 8px 12px;
          line-height: normal;
          font-size: 0.95rem;
          font-family: 'Avenir', sans-serif;
          text-align: left;
          text-transform: uppercase;
          background-color: #fff;
          color: #aeaeae;
          font-weight: 600;
          white-space: nowrap;
          border-radius: 4px 4px 0 0;
          border-bottom: 1px solid #ececec;

          &:hover {
            color: #333;
            cursor: pointer;
          }
        }
      }

      .row {
        display: table-row;
      }

      .row:not(.header) {
        height: 33px !important;
      }

      .row.parent-row .cell {
        font-weight: 600;
      }

      .row.footer .cell {
        border-top: 1px solid #ccc;
        font-weight: 600;
      }

      .center,
      & > .right {
        .row:not(.header) {
          .cell {
            padding: 7px 12px 6px 12px;
            font-size: 1em;
            font-family: 'Inconsolata', monospace;
            text-align: right;
          }
        }
      }

      .row:nth-child(odd):not(.footer-row):not(.footer):not(.primary):not(.secondary) {
        background-color: #fff;
      }
      .row:nth-child(even):not(.footer-row):not(.footer):not(.primary):not(.secondary) {
        background-color: #fafafa;
      }
      .row.primary {
        background-color: #fafafa;
        &.hover {
          .cell {
            background-color: #e6f2f2;
          }
        }
      }
      .row.secondary {
        background-color: #fff;
      }
      .row.footer {
        background-color: #f3f3f3;
        height: 34px !important;
      }
    }
  }
}
