#consent-banner {
  z-index: 3002;
  position: fixed;
  bottom: 0;
  background-color: #666;
  color: #fff;
  width: 100%;
  padding: 1em;
  font-weight: 600;
  display: none;

  &.show {
    display: block;
  }

  div.ui.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

#consent-table {
  .cell-type {
    width: 145px;
  }

  .cell-cookie {
    width: 140px;
  }

  td {
    padding: 12px 6px;
  }
}
