@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Avenir:400,700,400italic,700italic&display=swap');

// Fonts
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Inconsolata-latin.woff2') format('woff2');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/AvenirLTStd-Book.woff') format('woff');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/AvenirLTStd-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/AvenirLTStd-Heavy.woff') format('woff');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/AvenirLTStd-Heavy.woff') format('woff');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/AvenirLTStd-Heavy.woff') format('woff');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/AvenirLTStd-Black.woff') format('woff');
}

// Font family
$font-sans-serif: 'Avenir';
$font-monospace: 'Inconsolata';

// Colours
$green: #55b021;
$yellow: #f8b500;
$brand-yellow: #fbb700;
$brand-green: #44958c;
$white: #ffffff;
$black: #4f4f4f;
$black-2: #666666;
$grey: #aeaeae;
$grey-2: #dcdcdc;
$grey-3: #e4e4e4;
$red: #e05951;

// Buttons
$button-bg-color-disabled: $grey-3;
$button-bg-color: $yellow;
$button-text-color: $white;

// Font styles
$text-normal: #666666;

// Cadences
$cadence-yearly: #a82e63;
$cadence-twice-a-year: #a82e63;
$cadence-every-4-months: #a82e63;
$cadence-every-3-months: #7472c3;
$cadence-every-2-months: #7472c3;
$cadence-monthly: #7472c3;
$cadence-twice-a-month: #00a9ba;
$cadence-every-2-weeks: #00a9ba;
$cadence-once-a-week: #00a9ba;

$responsive-width: 850px;
