/*--------------------
   Input inside table
---------------------*/

.ui.input {
  /** Such that the transition from text to input is visually seamless
  margin-left: -1px;
  margin-top: -1px;
  margin-bottom: -1px;
  border-color: red;*/
}

// Editable text
.number {
  .g-editable-text {
    .ui.input > input {
      text-align: right;
    }
  }
}

.g-editable-text {
  .ui.input:not(.p-input-modal) > input {
    font-size: 1em !important;
  }
}

.p-input-modal.ui.input > input {
  font-size: 1.14em !important;
  line-height: normal;
}


/**
*  Edit Modal input
*/

.ui.form .ui.input.p-input-modal input,
.ui.input.p-input-modal input {
  padding: .5em .75em;
  border-color: @unfocusedInputBorderColor;

  &:hover, &:focus {
    border-color: @inputBorderColor;
  }
}

/* Budget button card */
.ui.form .ui.input.p-input-button input,
.ui.input.p-input-button input {
  width: 100%;
  padding: .4em .75em !important;
  border-color: @unfocusedInputBorderColor;

  &:hover, &:focus {
    border-color: @inputBorderColor;
  }
}


.ui.form .ui.input.p-input-modal-standalone input,
.ui.input.p-input-modal-standalone input {
  padding: .7em .75em !important;
  border-color: @unfocusedInputBorderColor;

  &:hover, &:focus {
    border-color: @inputBorderColor;
  }
}


.ui.form input.datepicker.p-input-modal {
  font-size: @large;
}

.ui.input.p-input-modal.nonfluid {
  width: 200px !important;
}
