.g-transactions,
.g-budget,
.g-query-tool,
#g-stats,
.apply-rules-modal {
  .add-to-cash-menu .menu.visible {
    right: auto !important;
    left: -139px !important;
  }

  .transaction-toolbar {
    .filter-button {
      margin-right: 0.35rem;
      padding: 5px 12px !important;

      @media (max-width: 575px) {
        max-height: 36px;
        margin-top: 1rem;
      }

      .filter-button-content {
        padding-top: 2px;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;

      .flex--space-between-flex-end {
        // Ensure the "Show toolbar" button is on the right side
        width: 100%;
      }
    }

    .show-more {
      display: none;
      @media (max-width: 1415px) {
        display: block;
      }
    }

    .transaction-toolbar-functions {
      margin-left: 0.5rem;
      display: flex;

      @media (max-width: 800px) {
        margin-left: 0;
        display: none;

        &.shown {
          justify-content: space-between;
          width: 100%;
          display: flex;
          margin-top: 1rem;

          @media (max-width: 575px) {
            flex-wrap: wrap;

            .transaction-toolbar-buttons {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }

  // Category dropdown - split transaction option
  .category-action {
    display: flex !important;
    align-items: center;

    .split-icon,
    .grouped-icon,
    .part-of-group-icon {
      margin-right: 10px;
    }
  }

  .automated-icon {
    position: relative;
    left: 3px;
    top: 4px;
    width: 15px;
    height: 16px;
    background-image: url('../images/automated.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
  }

  .rollover-icon {
    position: relative;
    left: 3px;
    width: 15px;
    height: 16px;
    background-image: url('../images/rollover.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
  }

  .part-of-group-icon {
    width: 16px;
    height: 16px;
    background-image: url('../images/part-of-group.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
  }
}

.split-icon {
  width: 16px;
  height: 13px;
  min-height: 13px;
  max-height: 13px;
  background-image: url('../images/split.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
}

.grouped-icon {
  width: 18px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  background-image: url('../images/grouped.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.recurring-icon {
  width: 13px;
  height: 13px;
  min-height: 13px;
  max-height: 13px;
  background-image: url('../images/recurring.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

#filter-modal {
  @media (max-width: 900px) {
    width: 90% !important;
  }
  @media (max-width: 700px) {
    width: 95% !important;
  }

  .filter-modal-content {
    padding: 0 !important;

    .sub-header {
      padding: 1em 1.5em;
      border-bottom: 1px dashed #dddddd;
    }
    .pre-footer {
      padding: 1em 1.5em;
      border-top: 1px dashed #dddddd;
    }

    .transaction-filter-popup {
      padding: 1em 1.5em;

      .filter-row {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        @media (max-width: 550px) {
          flex-direction: column;
          margin-bottom: 0.75rem;
          & > .display--flex {
            width: 100%;
            margin-bottom: 0.35em;
          }
        }

        .filter-row-criteria {
          // width: 140px;
        }

        .filter-row-relation {
          display: flex !important;
          align-items: center;
          & > .text {
            white-space: nowrap;
            padding-right: 4px;
          }
        }

        .filter-row-value {
          flex-grow: 1;
          width: 1px; // Hack?
          .g-editable-text .ui.input {
            #bulk-edit-name {
              color: #444 !important;
            }
            height: 32px;
            input {
              line-height: unset;
            }
          }
        }

        .ui.dropdown.filter-row-dropdown {
          display: flex !important;
          justify-content: space-between;
          background-color: #fff;
          color: #bbb;
          border-radius: 4px;
          font-family: 'Inconsolata';
          min-height: unset;
          padding: 8px 10px 6px 10px !important;

          &.filter-row-dropdown-criteria {
            width: 150px !important;
            min-width: unset;
          }

          input.search {
            top: 0px;
            min-height: unset;
            height: 30px;
            border-radius: 4px;
            left: 0;
            padding: 8px 10px !important;
            font-family: 'Inconsolata';
            color: #444;
            font-size: 1.14rem !important;
          }

          .disabled.item {
            font-size: 0.8rem;
            color: #444;
            padding: 10px 10px 8px 10px !important;
            font-weight: bold;
            text-transform: uppercase;
            font-family: 'Avenir', sans-serif;
            background-color: #f6f6f6;
            &:hover {
              background-color: #f6f6f6;
            }
          }

          div.default.text {
            color: #c9c9c9 !important;
          }

          &.error {
            input {
              border-color: #e0b4b4 !important;
              background-color: #fff7f6 !important;
              div.text {
                color: #9f3a38 !important;
              }
            }
          }

          & > .text {
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .message {
            font-size: 1.14rem;
          }
          div.text {
            min-height: 16px;
            color: #444;
            font-size: 1.14rem !important;
          }
          .menu > .item:not(.disabled),
          i.dropdown.icon {
            padding: 8px 10px !important;
          }
        }

        .ui.small.basic.icon.button {
          max-height: 32px;
        }
      }
    }
  }
}

.g-transactions {
  // 'Add new' and 'fetch' buttons
  .header-buttons {
    // height: 45px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    span.last-update {
      font-size: 0.8em;
      color: #ccc;
      text-transform: uppercase;
      font-weight: 500;
      display: inline-block;
      padding-right: 1em;
      @media (max-width: $responsive-width) {
        margin-top: 1em;
      }
    }

    @media (max-width: $responsive-width) {
      display: block;
      text-align: center;
    }
  }

  .search-transactions {
    input {
      min-height: 36px;
      font-size: 0.9em;

      &:hover {
        border-color: $brand-yellow !important;
      }

      &:focus {
        border-color: $brand-yellow !important;

        & + i {
          color: $brand-yellow !important;
        }
      }
    }
  }
}

// Main table

.ui.sortable.table thead th.no-hover,
.ui.sortable.table thead th.no-hover:hover {
  cursor: auto !important;
}

.p-transactions-table {
  .table-cell-toggle {
    width: 75px;
  }
  .table-cell-date {
    width: 106px;
  }
  .table-cell-category {
    width: 150px;
  }
  .table-cell-payee {
    // width: 160px;
  }
  .table-cell-amount {
    width: 130px;
  }
  .table-cell-notes {
    width: 200px;
    min-width: 150px;
  }
  .table-cell-account {
    min-width: 150px;
  }
  .table-cell-tags {
    width: 65px;
  }
  .table-cell-circle {
    width: 35px;
    text-align: center !important;
  }
  th.table-cell-circle {
    i.green.check.circle.icon {
      // position: relative;
      // right: 13px;
      margin-left: 1px !important;
      font-size: 1.18em !important;
    }
  }
  .table-cell-arrow {
    width: 35px;
    text-align: center !important;
  }
  .table-cell-small {
    width: 15px;
  }

  @media (max-width: $responsive-width) {
    .table-cell-date {
      width: 100px;
    }
    .table-cell-category {
      width: 100px;
    }
    .table-cell-amount {
      width: 100px;
    }
    .table-cell-payee {
      // width: 150px;
    }
    .table-cell-notes {
      width: 150px;
    }
    .table-cell-account {
      width: 150px;
    }
  }

  // Transaction row
  .transaction-row {
    &.delete-pending {
      background-color: lighten($red, 38%) !important;
    }

    &.pending {
      background-color: #fff !important;
      color: #a2a2a2 !important;

      &:hover {
        color: #777 !important;
      }
    }

    &.highlight {
      background-color: #e6f2f0;
    }

    &.updated {
      animation-name: color;
      animation-duration: 3s;
    }

    @keyframes color {
      0% {
        color: #666;
        background-color: #fff;
      }
      20% {
        color: #000;
        background-color: #e6f2f0;
      }
      100% {
        color: #666;
        background-color: #fff;
      }
    }

    /* Suggested recurring transaction */
    &.suggested-recurring {
      font-style: italic;
      color: #44958c;

      & .suggested-recurring-button {
        color: #44958c !important;
      }
    }

    /* Recurring transaction */
    &.recurring {
      color: #44958c;

      & .recurring-button {
        color: #44958c !important;
      }
    }

    /* Uncleared transaction, imported */
    &.uncleared {
      font-style: normal;
      color: #a2a2a2;

      // Not worth it because Apple.
      // https://stackoverflow.com/questions/47802530/a-click-in-ios-safari-triggers-a-hover-state-on-element-underneath-where-you-t
      // & .uncleared-button:hover {
      //   color: #21ba45 !important;
      // }
    }

    /* Saving transactions */
    &.saving {
      color: #a2a2a2 !important;
    }
  }
}

.interactive-budget-right {
  .p-transactions-table {
    .table-cell-date {
      width: 65px;
    }
  }
}

.p-budget-summary {
  .card-number {
    color: #666; // #55b021;

    &.critical {
      color: #e05951;
    }

    &.warning {
      color: #db921e;
    }

    &.plain {
      color: #666;
    }

    .percentage {
      display: inline-block;
    }

    .amount {
      display: none;
    }

    &:hover {
      cursor: default;
      .percentage {
        display: none;
      }
      .amount {
        display: inline-block;
      }
    }
  }
}

// Loading icon for clear all
.ui.tiny.inline.loader {
  margin-left: 7px;
}

/**
* Transaction summary bar selector
* Style to look like Card header
*/
.p-tx-summary-selector,
.p-tx-summary-selector.visible.active {
  border: 0 !important;
  text-align: center !important;
  min-height: unset !important;
  padding: 0 !important;
  box-shadow: none !important;

  i.dropdown.icon {
    padding: 0 !important;
  }
  .visible.menu.transition {
    border-top-width: 1px !important;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 0.85rem !important;
    background-color: #fff;
    .item {
      text-align: center;
    }
  }
  .text {
    color: #aeaeae !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 0.85rem;
    font-family: 'Avenir';
    text-align: center;
    margin-top: 2px;
    line-height: normal;
  }
}

.pagination-container {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .pagination-amount,
  .ui.pagination {
    &.mini {
      min-height: unset;
      .choice,
      .item {
        justify-content: center;
        min-width: 28px;
        font-size: 0.85rem !important;
        padding: 0.7rem 0.6rem 0.5rem 0.6rem !important;
      }
    }
  }

  .pagination-amount {
    display: flex;
    border: 1px solid #dcdcdc;
    border-radius: 3px;

    // &.mini {
    //   .choice {
    //     font-size: 0.8rem !important;
    //     padding: 0.6rem 0.5rem 0.5rem 0.5rem !important;
    //   }
    // }

    // Try to make the same as Pagination
    .choice {
      background-color: #fff;
      line-height: 1;
      color: #666;
      padding: 0.92857143em 1.14285714em;
      border-right: 1px solid rgba(34, 36, 38, 0.1);
      font-weight: 400;
      font-size: 14px;

      &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.95);
        background-color: rgba(0, 0, 0, 0.03);
      }
      &.active {
        color: rgba(0, 0, 0, 0.95);
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:last-child {
        border-right: 0px;
      }
    }
  }

  .item:focus,
  .item:active {
    outline: none;
  }
}

.search-transactions {
  z-index: 100 !important;
  .ui.selection.dropdown {
    margin-left: -1px;
    height: 36px;
    min-height: unset;
    width: fit-content;
    min-width: 105px;
    font-size: 1rem;
    font-family: 'Inconsolata', monospace;
    .visible.menu {
      span.text {
        font-size: 1rem;
      }
    }
  }
}

.right.menu.transition {
  right: 0 !important;
  left: unset !important;
}

.selected-payee-row {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}
