$error-bg: #e05951;
$error-light: #fff;

$success-bg: #55b021;
$success-light: #fff;
$info-bg: #44958c;
$info-light: #fff;
$warning-bg: #f8b500;
$warning-light: #fff;

.react-toast-notifications__container {
  margin: 1em 1em 0.5em 1em;
  .react-toast-notifications__toast {
    width: 310px !important;

    .react-toast-notifications__toast__icon-wrapper {
      width: 10px;
      svg {
        display: none !important;
      }
    }

    .react-toast-notifications__toast__content {
      b {
        font-weight: 500 !important;
      }
      padding: 1em 0 1em 1em !important;
      min-height: unset;
    }
    .react-toast-notifications__toast__dismiss-button {
      padding: 1em 1em 1em 0 !important;
    }
    &.react-toast-notifications__toast--error {
      // color is darker background color
      // background-color is entire background color
      background-color: lighten($error-bg, 26%);
      color: darken($error-bg, 16%);

      .react-toast-notifications__toast__icon-wrapper {
        // background-color is side color
        background-color: $error-bg;
        .react-toast-notifications__toast__countdown {
          background-color: darken($error-bg, 16%);
        }
      }
    }

    &.react-toast-notifications__toast--success {
      // color is darker background color
      // background-color is entire background color
      background-color: lighten($success-bg, 45%);
      color: darken($success-bg, 16%);
      .react-toast-notifications__toast__icon-wrapper {
        // background-color is side color
        background-color: $success-bg;
        .react-toast-notifications__toast__countdown {
          background-color: darken($success-bg, 10%);
        }
      }
    }

    &.react-toast-notifications__toast--info {
      // color is darker background color
      // background-color is entire background color
      background-color: lighten($info-bg, 36%);
      color: darken($info-bg, 16%);
      .react-toast-notifications__toast__icon-wrapper {
        // background-color is side color
        background-color: $info-bg;
        .react-toast-notifications__toast__countdown {
          color: darken($info-bg, 10%);
        }
      }
    }

    &.react-toast-notifications__toast--warning {
      // color is darker background color
      // background-color is entire background color
      background-color: lighten($warning-bg, 36%);
      color: darken($warning-bg, 16%);
      .react-toast-notifications__toast__icon-wrapper {
        // background-color is side color
        background-color: $warning-bg;
        .react-toast-notifications__toast__countdown {
          color: darken($warning-bg, 10%);
        }
      }
    }
  }
}

.dark-green-hover {
  &:hover {
    color: darken($success-bg, 10%) !important;
  }
}

.dark-red-hover {
  &:hover {
    color: darken($error-bg, 15%) !important;
  }
}

.dark-grey-hover {
  &:hover {
    color: darken(#cacaca, 10%) !important;
  }
}
