.log-container {
  border-radius: 4px;
  max-width: 100%;
  padding: 0rem;
  margin-bottom: 0.5rem;
  color: #919191;

  display: flex;

  .log-icon {
    display: flex;
    flex-direction: column;
    padding-right: 0.8rem;

    .log-icon-line {
      position: relative;
      margin-top: 0.5rem;
      width: 50%;
      border-right: 1px solid #bbb;
      flex-grow: 1;
      &.thick {
        border-width: 2px;
      }
      &.yellow {
        border-right-color: $brand-yellow;
      }
      &.grey {
        border-right-color: #bbb;
      }
      &.red {
        border-right-color: $red;
      }
      &.moving-green {
        border-right-color: $green;
        border: 0;
        position: relative; /* Needed for the positioning of the pseudo-element */
        overflow: hidden; /* Prevents the moving effect from extending outside the div */

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0; /* Aligns the effect with the right border */
          bottom: 0;
          width: 2px; /* Ensures the effect is visible */
          background: repeating-linear-gradient(
            to bottom,
            $green 0,
            $green 2px,
            /* Single pixel dash */ transparent 1px,
            transparent 4px /* Adjust to control the dash spacing */
          );
          background-size: 2px 6px; /* Adjust this value based on the total size of one dash plus one gap */
          animation: moveDashes 1s linear infinite;
        }
      }

      .mid {
        position: absolute;
        top: calc(50% - 11px);
        right: -13px;
        background-color: #fff;
      }
    }
  }

  @keyframes moveDashes {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 12px; /* This should match the total height of one dash plus one gap */
    }
  }

  &.log-container:last-child {
    .log-icon-line {
      border-right: 0;
    }
  }
  .log-details {
    width: 100%;
    span.log-header {
      font-size: 0.8em;
      font-weight: 600;
      display: block;
      text-align: left;
      color: #666;
      text-transform: uppercase;
      padding-top: 5px;
      padding-bottom: 2px;

      &.large {
        font-size: 1em;
        font-weight: 700;
      }
    }
    .log-items {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      span.log-item {
        margin-bottom: 0.2rem;
        display: block;
        color: #444;
      }
      .monospace {
        color: #666;
      }
    }
  }

  h1 {
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
  }
}
