#g-billing {
  h3 {
    margin-bottom: 0;
  }
  display: flex;

  & > div:first-child {
    width: 50%;
    margin-right: 1em;
  }

  & > div:last-child {
    width: 50%;
  }

  @media (max-width: $responsive-width) {
    flex-direction: column;

    & > div:first-child {
      width: 100%;
      margin-right: 0em;
      margin-bottom: 1em;
    }

    & > div:last-child {
      width: 100%;
    }
  }

  .plan-details-container {
    @media (min-width: $responsive-width) and (max-width: 1400px) {
      flex-direction: column;
    }
    display: flex;
  }

  .plan-details {
    width: 100%;
    // background-color: #fff;
    border: 1px solid #e5e5e5;
    padding: 1em 1em 0.9em 1em;
    border-radius: 4px;
    margin-bottom: 24px;

    &:first-child {
      margin-right: 0.5rem;
    }
    &:last-child {
      margin-left: 0.5rem;
    }

    @media (min-width: $responsive-width) and (max-width: 1400px) {
      &:first-child {
        margin-right: 0rem;
      }
      &:last-child {
        margin-left: 0rem;
      }
    }

    &.important {
      border-color: $brand-yellow;
    }
    &.warning {
      border-color: $red;
    }

    &.selected {
      border-color: $brand-green;

      .header-style {
        color: $brand-green;
        border-color: $brand-green;
      }
    }

    .header-style {
      display: flex;
      justify-content: space-between;
      color: #aaa;
      font-weight: 600;
      border-bottom: 1px solid #eee;
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-family: 'Avenir';
      padding-bottom: 0.5rem;
      font-size: 1em;
      align-items: flex-start !important;

      .emph {
        background-color: $brand-green;
        text-transform: uppercase;
        border-radius: 5px;
        color: #fff;
        padding: 5px 6px 3px 6px;
        line-height: 0.8rem;
        font-size: 0.7rem;
        margin-top: -3px;
      }
    }

    .sub-header {
      padding-bottom: 1rem;
    }

    span {
      display: block;
      padding-top: 2px;
      padding-bottom: 2px;
      font-family: 'Inconsolata';
    }

    &.clickable.icon {
      position: relative;
      top: -2px;
      color: #e5e5e5 !important;
    }

    &.clickable:hover {
      border-color: $brand-green;
      .header-style {
        color: $brand-green;

        .icon {
          color: $brand-green !important;
        }
      }
    }
  }

  .transaction-detail {
    margin-bottom: 12px;

    label {
      font-size: 0.9em;
      font-family: 'Avenir';
      color: #bbb;
      text-transform: uppercase;
      font-weight: 600;
    }

    span:not(.sizer) {
      margin-top: 1px;
      display: block;
      font-family: 'Inconsolata';
      font-size: 1.1em;
      // color: $text-normal;
    }

    & .name {
      font-size: 1.3em;
      font-weight: 600;
    }
  }

  .StripeElement {
    padding: 0 !important;
    border: 0 !important;
  }
}
