.g-tags {
  .p-tags-table {
    min-width: 500px !important;
    .table-cell-color {
      width: 70px;
    }
    .table-cell-name {
      width: 200px;
    }
    .table-cell-rules {
      width: 100px;
    }
    .table-cell-settings {
      width: 50px;
    }
    // From merchant name
    .table-cell-category {
      width: 230px;
    }
    .table-cell-amount {
      width: 130px;
    }
    .table-cell-matches {
      width: 100px;
    }
  }
}
