#g-import {
  .p-import-container {
    height: 100%;
    margin-top: 18px;
    display: flex;

    & > .left {
      height: fit-content;

      @media (max-width: 1365px) {
        overflow-x: hidden;
        overflow-y: visible;
      }

      table:not(.expanded):not(.flexible):not(.p-transactions-table) {
        min-width: 950px;
      }
      table.flexible {
        // min-width: 950px;
      }
      table:not(.flexible).expanded {
        min-width: 625px;
      }
    }

    & > .right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 1rem;
      min-width: calc(260px + 1rem);
      height: 100%;
    }
  }
  .ui.message {
    width: 100%;
  }
  .ui.dropdown .menu > .item {
    display: flex;
  }
  .table-cell-200 {
    width: 200px;
  }
}

.droppable-table-cell {
  height: 100%;
  min-height: 75px;
  width: 100%;
  border: 1px solid #fbb700;
  color: #fbb700;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2em 1.5em;
}

.replace-padding {
  padding: 0.5em !important;
  min-height: unset !important;
}

.p-csv-import-table {
  tr {
    background-color: #fff !important;
    td {
      padding: 10px !important;
    }
  }
}

.csv-settings-table {
  margin-top: 0 !important;
  width: 100%;
  .table-cell-date-old {
    width: 125px;
    padding-right: 0px !important;
  }
  .table-cell-date-new {
    width: 125px;
    padding-left: 0px !important;
  }

  .table-cell-arrow {
    width: 60px;
    padding: 0 !important;
  }

  .table-cell-price {
    width: 105px;
    padding-right: 0px !important;
  }
  .table-cell-price-type {
    width: 105px;
    padding-left: 0px !important;
  }
}

.draggable-card {
  .ui.card {
    margin-bottom: 1em !important;
    margin-right: 1em !important;
  }

  .hide-text {
    text-align: right;
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 600;
    color: #ccc;
    position: absolute;
    right: 11px;
  }

  &.assigned {
    .ui.card {
      background-image: url('../images/checkmark.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50px;
      opacity: 0.5;
    }
  }

  &.hidden {
    .ui.card {
      min-height: 35px;
      p.monospace {
        display: none;
      }
    }
  }
}

@media (max-width: 1200px) {
  .draggable-card > .ui.card {
    max-width: 220px;
  }
}

.draggable-card:hover {
  cursor: pointer;
  .ui.card {
    box-shadow: 1px 1px 5px #ddd !important;
  }
}

.remove-button {
  width: 100%;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #ddd;
  color: #ddd;
  margin-top: 0.5em;

  &:hover {
    background-color: $red;
    border-color: $red;
    color: $white;
    cursor: pointer;
  }
}
