.button-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 150px;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 1px #eee;
  padding: 0.75em;

  &:nth-child(2) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  &:nth-child(3) {
    margin-right: 0.5rem;
  }

  &.disabled {
    border-color: #eee;

    .task-text > .title,
    .task-text > .subtitle {
      color: #ddd;
    }

    .checkmark {
      display: none;
    }

    &:hover {
      border-color: #eee !important;
      cursor: default !important;
    }
  }

  &.selected {
    border-color: #21ba45;

    & .checkmark {
      background-image: url('../images/checkmark-white.png') !important;
      border-color: #21ba45 !important;
      background-color: #21ba45 !important;
    }

    &:hover {
      opacity: 1;
    }
  }

  &:not(.task-done) {
    // border-color: #e05951
  }

  &:hover:not(.selected):not(.disabled) {
    border-color: #bbb;
    // box-shadow: 0 1px 1px #eee;
    // position: relative;
    // top: 1px;
    // left: 1px;
    cursor: pointer;

    .checkmark {
      background-image: url('../images/checkmark-white.png');
      border-color: #ddd;
      background-color: #ddd;
    }
  }

  .top-right-icon {
    position: absolute;
    top: 0.6em;
    right: 0.6em;

    &.checkmark {
      border: 1px solid #ddd;
      border-radius: 12px;
      width: 18px;
      height: 18px;
      background: url('../images/checkmark.png') center no-repeat;
      background-size: 8px 8px;
    }
  }

  .task-text {
    display: flex;
    flex-direction: column;

    .title {
      font-family: 'Inconsolata', monospace;
      text-align: center;
      margin-top: 2px;
      margin-bottom: 4px;
      display: block;
      color: #666666;
      font-size: 1.1em;
      font-weight: 600;
    }

    .subtitle {
      text-align: center;
      display: block;
      font-weight: 400;
      color: #888;
      font-size: 0.85em;
      line-height: 1.2em;
    }
  }
  .editable-price-default-state {
    font-family: 'Inconsolata', monospace;
    text-align: center;
    display: block;
    color: #666666;
    font-size: 1.1em;
    font-weight: 600;
  }
}
