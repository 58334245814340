/*******************************
         Theme Overrides
*******************************/

/**
 * Top menu (Home / Finances / Setup)
 */

.ui.tabular.menu .item {
  font-size: @medium;
  text-transform: uppercase;
}

.ui.tabular.menu .active.item {
  border-top-width: 0px;
}

/**
 * Secondary menu (Transactions / Recurring / Budget)
 */

.ui.secondary.pointing.menu {
  box-shadow: 0px 1px 0 #eee;
}

.ui.secondary.menu .item {
  color: @lightGreyText;
  font-size: @medium;
  text-transform: uppercase;
}

/**
Mobile menu
*/

.ui.vertical.menu .item > .header:not(.ui) {
  border-bottom: 1px solid #44958c;
  padding-bottom: 8px;
  color: #44958c;
}

.ui.fluid.secondary.vertical.menu {
  margin: 1em 0 !important;
  border-bottom: 1px solid #ccc;
}


/**
Budget menu
*/

.interactive-budget {
  .ui.pointing.vertical.menu {
    border-radius: 3px !important;
    font-size: 0.95em !important;

    .item.header {
      color: #aeaeae;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-size: .85714286rem;
      text-align: center;
      line-height: normal;
      margin-top:2px;
    }

    .item:not(.header) {
      padding: 0.9em 1em 0.6em 1em !important;
      font-weight: 400 !important;
      display: flex;
      justify-content: space-between;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.active {
        font-weight: 600 !important;
      }

      i.check.circle.icon {
        color: #55b021 !important;
      }
      i.exclamation.triangle.icon {
        color: #e05951 !important;
      }
    }
  }
}
