/**
* Toast messaging system
*/

.toast {
  z-index: 1002;
  // position: fixed;
  // bottom: 25px;
  text-align: left;
  transition: all 0.6s ease;
  opacity: 0;

  .toast-message {
    font-weight: 400;
    display: flex;
    background-color: #fff;
    border: 1px solid #ccc;
    color: #666;
    padding: 10px 20px;
    border-radius: 10px;
    max-width: 300px;

    &.error {
      border: 1px solid #e05951;
      color: #e05951;

      & i {
        color: #e05951;
      }
    }

    & i {
      padding-top: 2px;
    }

    & span {
      display: inline-block;
      padding-left: 12px;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &.visible {
    opacity: 1;
    left: 25px;
  }

  &.hidden {
    opacity: 0;
    left: -500px;
  }
}
