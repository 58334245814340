.segment-container {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 1em;
  border-radius: 4px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  h3 {
    color: #666;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Avenir';
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.6em;
    font-size: 1em;
    // text-transform: uppercase;
    display: flex;

    &.centered {
      align-items: center !important;
    }
    align-items: flex-start;

    span {
      font-family: 'Inconsolata';
      font-size: 1em;
      font-weight: bold;
      text-transform: none;
      top: -1px;
      position: relative;
    }
  }
}

.selection-container {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 1em;
  border-radius: 4px;
  margin: 0 1rem 1rem 0;
  display: flex;
  min-width: 350px;
  width: 30%;

  &:hover {
    border-color: #666;
    cursor: pointer;
  }

  &.selected {
    border-color: $brand-green;
    h3 {
      color: $brand-green;
    }
  }

  .selection-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    color: #666;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Avenir';
    // border-bottom: 1px solid #ccc;
    // padding-bottom: 0.6em;
    font-size: 1em;
    // text-transform: uppercase;
    display: flex;

    .icon {
      color: #e5e5e5;
    }
  }
}

.drop-area {
  width: 100%;
  height: 175px;
  border: 6px dashed #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inconsolata';
  text-align: center;
  margin-bottom: 0.5rem;
}

.file-list {
  // padding: 0 1rem 1rem 1rem;
  .file-item {
    font-family: 'Inconsolata';
  }
}
