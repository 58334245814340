@import 'vars';
@import 'common';
@import 'labels';
@import 'modal';
@import 'toast';
@import 'task_card';
@import 'button_card';
@import 'files';
@import 'menu';
@import 'feedback';
@import 'stripe';
@import 'consent_banner';
@import 'toast_override';
@import 'elements/editable_date';
@import 'elements/tables';
@import 'elements/tooltips';
@import 'elements/tabular';
@import 'elements/segment';
@import 'pages/admin';
@import 'pages/billing';
@import 'pages/budget';
@import 'pages/budget_settings';
@import 'pages/calendar';
@import 'pages/developers';
@import 'pages/integrations';
@import 'pages/logged_out';
@import 'pages/changelog';
@import 'pages/gift_cards';
@import 'pages/categories';
@import 'pages/dashboard';
@import 'pages/linked_accounts';
@import 'pages/log';
@import 'pages/register';
@import 'pages/onboarding';
@import 'pages/recurring';
@import 'pages/transactions';
@import 'pages/import';
@import 'pages/locked_out';
@import 'pages/overview';
@import 'pages/query_tool';
@import 'pages/referral';
@import 'pages/tags';
@import 'pages/trends';
@import 'pages/rules';
@import 'pages/summary';
@import 'pages/status';
@import 'pages/loading';
@import 'pages/networth';
@import 'pages/quick_add';
@import 'mui';

#root {
  padding-bottom: 7rem;
}
/** Dark mode by browser setting */
@media (prefers-color-scheme: dark) {
  body:not(.no-dark) {
    @import '__dark.scss';
    color: $dm-text-lightest !important;
    background-color: $dm-background !important;
  }
}

/** Dark mode by override */
body:not(.no-dark).dark-mode {
  @import '__dark.scss';
  color: $dm-text-lightest !important;
  background-color: $dm-background !important;
}

.g-transactions {
  & > .p-content-container,
  & > .p-content-container > .left,
  & > .p-content-container > .left > .table-container {
    min-height: 350px;
  }
}

.table-container {
  height: auto;

  @media (max-width: 1415px) {
    overflow-x: auto;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fcfcfc;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #fcfcfc; //#eaeaea;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eaeaea;
  }
}
.p-content-container {
  height: 100%;
  margin-top: 18px;
  display: flex;

  &.stacked {
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
    }
  }

  & > .left {
    flex-grow: 1;
    height: fit-content;

    @media (max-width: 1365px) {
      overflow-x: hidden;
      overflow-y: visible;
    }

    table:not(.expanded):not(.flexible):not(.p-transactions-table) {
      min-width: 950px;
    }
    table.flexible {
      // min-width: 950px;
    }
    table:not(.flexible).expanded {
      min-width: 625px;
    }
  }

  & > .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 1rem;
    min-width: calc(260px + 1rem);

    .search-space {
      height: 59px;
    }

    @media (max-width: 1000px) {
      flex-direction: row;
      align-items: flex-start;
      .ui.card.card-to-shorten {
        width: 50%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        &:first-child {
          margin-right: 1rem !important;
        }
      }
    }
  }

  @media (max-width: 992px) {
    padding-bottom: 1em;
  }
}

// Monthly Summary status bar

.card-content-wrapper {
  font-size: 1em;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }

  &.footer {
    margin-bottom: 0;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;

    span:not(.amount) {
      color: #666;
      font-weight: 400;
    }

    span.card-text {
      width: 70%;
      color: #737373;
      padding-right: 20px;
      font-size: 0.95em;
      font-weight: 400;

      i.icon {
        width: auto;
        margin: 0 4px 0 0;
      }

      span.secondary {
        display: block;
        font-size: 0.9em;
        font-family: $font-sans-serif;
        color: #ababab;
        font-weight: 500;
      }
    }

    span.card-number {
      font-family: $font-monospace;
      font-size: 1rem;
    }
  }
}

// Used for budget summary and onboarding
.status-bar-background {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #efefef;
  border-radius: 5px;
  overflow: hidden;

  &.shortened {
    width: 90%;
    align-self: flex-end;
  }

  .status-bar {
    position: absolute;
    background-color: #fbb700;
    height: 100%;
    border-radius: 5px;
    &.appended {
      border-radius: 0 5px 5px 0;
    }
    &.middle {
      border-radius: 0;
    }
    &.prepended {
      border-radius: 5px 0 0 5px;
    }
    transition: width 0.4s ease-in-out;

    &.normal {
      background-color: #21ba45;
    }
    &.critical {
      background-color: #e05951;
    }
  }

  .today-line {
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 2px;
  }
}

// Footer

footer {
  padding-top: 60px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #bababa;

  &.with-banner {
    margin-bottom: 80px;
  }

  a {
    color: #bababa;
    text-decoration: none;
    border-bottom: 1px dashed #ddd;

    &:hover {
      border-bottom-width: 0px;
      color: #666666;
    }
  }
}

// Transaction header with arrows

.toggle-header-container {
  display: flex;
  margin: 4rem 0 2rem 0;
  justify-content: space-between;

  h1 {
    margin-top: 0.8rem !important;
    margin-bottom: 0 !important;
  }

  #search-results-header {
    margin-top: 2.35rem !important;
  }

  .toggle-header {
    display: flex;
    align-items: center;
    position: relative;
    left: -40px;

    .icon {
      margin: 0px 12px;
    }

    @media (max-width: $responsive-width) {
      left: 0px;
      text-align: center;
    }
  }

  .ui.dropdown {
    align-self: center;
  }

  @media (max-width: $responsive-width) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    .ui.dropdown {
      margin: 0 auto;
      align-self: flex-start;
    }
  }
}

// Transaction header with arrows

.header-container {
  margin: 50px 0;
}

// Editable text
.g-editable-text {
  // flex-grow: 1;
  font-size: 1em;
  &:not(.horizontal-bar-number):not(.overview-amount) {
    height: 37px;
  }
  display: block !important;
  align-items: center;

  // For net worth tracker and spending breakdown
  &.short {
    height: 32px !important;
    .default-state {
      height: 32px !important;
    }
  }

  span:not(.category-action):not(.secondary):not(.label-style):not(.split-icon):not(.recurring-icon):not(.grouped-icon):not(.react-datepicker__year-read-view--down-arrow):not(.react-datepicker__year-option--selected) {
    display: inline-block;
    width: 100%;
  }

  .react-datepicker__close-icon::after {
    background-color: $red;
  }

  .react-datepicker__navigation-icon::before {
    width: 12px !important;
  }

  .empty-state {
    font-style: normal;
    color: #bbb;
  }

  .readonly {
    padding: 9px 13px;
    white-space: nowrap;
    overflow: hidden;
  }

  .default-state {
    border: none;
    padding-left: 13px;
    padding-right: 13px;
    width: 100%;
    height: 36px; // should be no
    display: flex;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // &.cell-metadata {
    //   display: block;
    // }

    &:hover {
      position: relative;
      z-index: 9;
      min-height: 24px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .ui.input.smaller-width {
    position: relative;
    left: 1px;
    border-right: 0px;
    margin: 0; /** Removes weird height discreps */
    width: calc(100% - 45.5px);

    &.short > input {
      padding: 0 0 0 7px !important;
      border-radius: 4px 0 0 4px;
    }
  }
}

.editable-input-mui-inline.selected {
  // border: 0px !important;
}

// loader screen
.g-loader-row {
  .loader-cell {
    background: url('../images/loader-bg.svg') no-repeat;
    background-size: cover;
    background-position: center center;
    height: 200px;
    margin-top: 14px;
    display: flex;
    align-items: center;
  }
}

// Single loader row
.loader-row {
  text-align: center !important;
  height: 34px;

  .ui.inline.loader {
    top: -4px;
  }

  span {
    position: relative;
    top: 2px;
    display: inline-block;
    margin-left: 10px;
    color: #bfbfbf;
  }
}

// Recurring expense label ( or any other table label ? )

.suggested-row {
  color: #a2a2a2;
}
.suggested-label {
  margin: 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: center;
  padding: 2px 5px 3px 5px !important;
  border-radius: 2px;
  font-size: 0.8rem !important;
  font-family: 'Inconsolata';
  background-color: #ddecea;
  font-weight: 300;
  color: #44958c;
  text-transform: uppercase;
  white-space: nowrap;
}

// Hide arrows on number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
