@media (min-width: 767px) and (max-width: $responsive-width) {
  div.nav-mobile {
    display: block;
    &.has-fixed-banner {
      margin-top: 48px;
    }
  }
  div.nav-full {
    display: none;
  }
}
@media (max-width: 767px) {
  div.nav-mobile {
    &.has-fixed-banner {
      margin-top: 67px;
    }
  }
  div.nav-full {
    display: none;
  }
}

@media (min-width: $responsive-width) {
  div.nav-mobile {
    display: none;
  }
  div.nav-full {
    display: block;
    &.has-fixed-banner {
      margin-top: 3em;
    }
  }
}

.fixed-banner {
  text-align: center;

  &.billing {
    background-color: $brand-green;
  }
  &.demo {
    background-color: $green;
  }
  &.error {
    background-color: $red;
  }
  &.verify {
    background-color: #a3333d;
  }
  &.warning {
    background-color: $brand-yellow;
  }
  color: #fff;
  font-weight: 600;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &.banner-overlay {
    background-color: #666666;
    z-index: 11 !important;

    span {
      display: block;
      padding: 0.5em;
      color: #fff;
    }
  }

  a {
    position: relative;
    top: 1px;
    padding: 0.5em;
    display: block;
    color: #fff;
    &:hover {
      color: #fff !important;
    }
  }
}
