.g-dashboard {
  .g-editable-text .readonly,
  span.ellipsis {
    // Override for price
    padding: 0 !important;
  }
  .highlighted-row {
    background-color: #e6f2f0 !important;

    .status-bar-background {
      background-color: #fff !important;
    }
  }

  span.italic {
    font-style: italic;
    padding: 0 !important;
  }

  span.excluded {
    display: block;
    width: 100%;
    color: #ccc;
    padding: 0 !important;
    text-align: center;
  }

  .spending-breakdown-table {
    min-width: 500px !important;
    .selected-sort {
      color: #666;
    }

    tbody tr:hover {
      background-color: #e6f2f0 !important;
      .status-bar-background {
        background-color: #fefefe !important;
      }
    }

    tbody tr td {
      padding: 10px 0 10px 0 !important;
    }

    td:first-child {
      padding-left: 2px !important;
    }

    td:last-child {
      padding-right: 2px !important;
    }

    thead tr th {
      padding-top: 0.75em !important;
    }

    .spending-number {
      padding-right: 0px;
      text-align: right;
    }
    .spending-category-name {
      max-width: 180px !important;
      padding-right: 1rem;
      & > span {
        display: block !important;
        padding: 0 1rem 0 0 !important;
      }
    }
    .spending-bar {
      width: 100%;
    }

    td.monospace > span,
    td.monospace > .g-editable-text > span {
      padding: 0 0 0 15px !important;
    }
  }

  .breakdown-scroll-container {
    overflow-x: auto;
  }

  .horizontal-bar-container {
    min-width: 500px;
    margin-bottom: 1rem;

    .label {
      font-size: 0.9em;
      font-weight: 600;
      display: block;
      text-align: left;
      color: #666;
      text-transform: uppercase;
    }

    .horizontal-bar-row {
      border-radius: 20px;
      margin-bottom: 1rem;
      margin-top: 0.2rem;

      display: flex;
      align-items: flex-start;
      background-color: #efefef;
      position: relative;
      height: 23px;

      .horizontal-bar {
        position: absolute;
        display: flex;
        height: 100%;
        width: 100%;

        .bar-section {
          position: relative;
          top: 0;
          margin: 0 1px;
          height: 100%;
          transition: all 0.2s linear;
          min-width: 1px;

          &.highlighted-bar,
          &:hover {
            height: 130%;
            top: -15%;
          }
        }

        .bar-section:first-child {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        .bar-section:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
  }
}
