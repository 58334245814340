#g-admin {
  display: flex;
  align-items: flex-start;

  & > div:first-child {
    width: 50%;
    margin-right: 1em;
  }

  & > div:last-child {
    width: 50%;
  }

  .less-card-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  @media (max-width: $responsive-width) {
    flex-direction: column;

    & > div:first-child {
      width: 100%;
      margin-right: 0em;
      margin-bottom: 1em;
    }

    & > div:last-child {
      width: 100%;
    }
  }
}

#g-admin-menu {
  .ui.menu .active.item {
    font-weight: 600 !important;
  }
}

#g-admin-container {
  input {
    font-size: 1.1rem;
  }

  // editable dates
  .g-editable-text {
    width: 130px;
  }

  .ui.dropdown {
    padding: 0.1em 0.75em !important;
    line-height: 2rem;
    height: 33px;
    min-height: unset;
    width: 150px;
    font-family: 'Inconsolata';
    font-size: 1.1rem;

    i.dropdown.icon {
      top: 5px !important;
    }

    .menu > .item {
      font-family: 'Inconsolata';
      font-size: 1.1rem;
    }
  }

  #input-field {
    flex-grow: 1;

    .ui.input {
      width: 100%;
    }
  }
}
