#g-integrations {
  .integrations-container {
    display: flex;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    .crypto-integration-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border: 1px solid #eee;
      width: 200px;
      height: 100px;
      padding: 1rem;
      border-radius: 4px;
      margin-right: 1rem;
      margin-bottom: 1rem;
      &:hover:not(.disabled),
      &.selected {
        cursor: pointer;
        border-color: #aaa;
      }
      &.not-selected {
        opacity: 0.4;
      }
      .recommended {
        position: absolute;
        width: 100%;
        left: 0.5rem;
        bottom: 0.5rem;
      }
      img {
        padding: 1rem;
        max-width: 200px;
        max-height: 100px;
      }
    }
  }
}
