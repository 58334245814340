.g-logged-out {
  background-image: url('../images/bg.jpg');
  background-repeat: repeat;
  background-size: 50px 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow-y: scroll;

  a {
    font-weight: 700;

    &:hover {
      color: #444;
    }
  }

  .g-logged-out-container {
    margin: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .logged-out-content {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 0 4px 3px #eee;
      width: 50%;
      max-width: 400px;
      margin: 4em auto 4em auto;
      padding: 3em 2.5em;

      &.wider {
        max-width: 440px;
      }

      @media (max-width: $responsive-width) {
        width: 70%;
        padding: 2em;
      }

      @media (max-width: 700px) {
        width: 80%;
      }

      @media (max-width: 500px) {
        width: 85%;
      }
      @media (max-width: 400px) {
        width: 90%;
        margin: 2em auto;
        padding: 2em 1.5em;
      }

      .message {
        text-align: center;
        margin: 0;
      }

      .error {
        text-align: center;
        color: #e05951;
        margin: 0;
        &.login-error {
          margin-top: 8px;
        }
      }

      .logged-out-form {
        width: 100%;
        margin: 1em 0 2em 0;
        display: flex;
        flex-direction: column;

        button {
          margin-top: 1em;
        }
      }

      .forgot {
        width: 100%;
        text-align: right;
      }

      p.register {
        text-align: center;
      }
    }
  }
}
